import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import * as React from "react";
import { formatRoute } from "react-router-named-routes";
import { Suite } from "../../../generatedApi";
import { Result } from "../../../stores/RegressionStore";
import { TREND_PATH } from "../../Routes";
import { styles } from "../../Styles/layout";
import Link from "../../UI/Link";
import TrendData from "../../UI/TrendData";

interface ISuiteItemProps {
  result: Result;
  suiteId: Suite["uuid"];
  projectId: string;
  classes: any;
}

const SuiteListItem = (props: ISuiteItemProps) => {
  const { result, suiteId, projectId, classes } = props;
  return (
    <React.Fragment>
      <TableCell align="left" className={classes.borderRight}>
        <Link
          to={{
            pathname: formatRoute(TREND_PATH, { projectName: projectId, suiteId, test: result.name }),
          }}
        >
          {result.name}
        </Link>
      </TableCell>
      <TableCell align="right">
        {result.metricNames.map((value: { name: string; units: string }, index: number) => {
          return (
            <TrendData
              key={index}
              delta={result.delta.measurements[value.name] ? result.delta.measurements[value.name].value : 0}
            >
              {result.average.measurements[value.name] ? result.average.measurements[value.name].value : null}
            </TrendData>
          );
        })}
      </TableCell>
      <TableCell align="right">
        {result.metricNames.map((value: { name: string; units: string }, index: number) => {
          return (
            <TrendData
              key={index}
              delta={result.delta.measurements[value.name] ? result.delta.measurements[value.name].value : 0}
            >
              {result.latest.measurements[value.name] ? result.latest.measurements[value.name].value : null}
            </TrendData>
          );
        })}
      </TableCell>
      <TableCell align="right">
        {result.metricNames.map((value: { name: string; units: string }, index: number) => {
          return (
            <TrendData
              key={index}
              delta={result.delta.measurements[value.name] ? result.delta.measurements[value.name].value : 0}
            >
              {result.delta.measurements[value.name] ? result.delta.measurements[value.name].value : null}
            </TrendData>
          );
        })}
      </TableCell>
      <TableCell align="left">
        {result.metricNames.map((value: { name: string; units: string }, index: number) => {
          return (
            <TrendData
              key={index}
              delta={result.delta.measurements[value.name] ? result.delta.measurements[value.name].value : 0}
            >
              {value.name} {value.units}
            </TrendData>
          );
        })}
      </TableCell>
    </React.Fragment>
  );
};
export default withStyles(styles)(SuiteListItem);
