import { action, observable, reaction } from "mobx";
import { createContext } from "react";

export interface ISnackBarData {
  message: string;
  variant: "success" | "warning" | "error" | "info";
  className?: string;
}

export class SnackBar {
  @observable public data: ISnackBarData;
  @observable public isActive: false | true;

  public reaction1 = reaction(() => this.data, data => this.show());

  constructor() {
    this.data = { message: "", variant: "info" };
    this.isActive = false;
  }

  @action public setData(data: ISnackBarData) {
    this.data = data;
  }

  @action public close() {
    this.isActive = false;
  }

  @action public show() {
    this.isActive = true;
  }
}

export const SnackBarStore = new SnackBar();
export default createContext(SnackBarStore);
