import { action, computed, observable, runInAction } from "mobx";
import { createContext } from "react";
import { TestSuiteMembership } from "../generatedApi";
import { projectApi, suiteApi } from "../services/api";

export class TestMembershipsStore {
  @observable private membershipsRegistry = observable.map<string, TestSuiteMembership>();
  @observable private projectName: string = "";

  @computed get memberships() {
    return Array.from(this.membershipsRegistry.values());
  }
  set memberships(memberships: TestSuiteMembership[]) {
    this.membershipsRegistry.clear();
    memberships.forEach(membership => {
      this.membershipsRegistry.set(membership.test_name, membership);
    });
  }

  public getMembership(testName: string) {
    const membership = this.membershipsRegistry.get(testName);
    if (membership) {
      return membership;
    }
    return {
      test_name: "",
      suite_names: [],
    };
  }

  @action public setProject(projectName: string) {
    if (this.projectName !== projectName) {
      this.membershipsRegistry.clear();
      this.projectName = projectName;
    }
  }

  @action public async loadMemberships(suiteName?: string) {
    try {
      const response = await projectApi.getTestMembership(this.projectName, suiteName);
      this.memberships = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async createMembership(suiteUuid: string, testName: string) {
    try {
      await suiteApi.addTestToSuite(this.projectName, suiteUuid, testName);
      await this.loadMemberships();
    } catch (err) {
      throw err;
    }
  }

  @action public async deleteMembership(suiteUuid: string, testName: string) {
    try {
      await suiteApi.deleteTestFromSuite(this.projectName, suiteUuid, testName);
      await this.loadMemberships();
    } catch (err) {
      throw err;
    }
  }
}

const membershipStore = new TestMembershipsStore();
export default createContext(membershipStore);
