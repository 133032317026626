export function roleValueToName(roleValue: string) {
  switch (roleValue) {
    case "admin":
      return "Admin";
    case "editor":
      return "Editor";
    case "viewer":
      return "Viewer";
    default:
      return "Unknown";
  }
}
