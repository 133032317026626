import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import { NavLink } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    link: {
      "&:hover": {
        textDecoration: "none",
      },
      color: theme.palette.secondary.main,
    },
  });

interface IProps extends WithStyles<typeof styles> {
  to: string;
  children: any;
  [index: string]: any;
}

const Link = (props: IProps) => (
  <NavLink className={props.classes.link} to={props.to} {...props}>
    {props.children}
  </NavLink>
);
export default withStyles(styles)(Link);
