import { Button, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { ErrorBody } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import AppContext from "../../../stores/AppStore";
import history from "../../../stores/History";
import NotificationContext from "../../../stores/NotificationStore";
import projectsContext from "../../../stores/ProjectsStore";
import { PROJECTS_PATH } from "../../Routes";
import { styles } from "../../Styles/layout";

interface IProps {
  dialogActive: boolean;
  dialogToggle: () => void;
}

export const AddProject = observer((props: IProps) => {
  const [nameError, setNameError] = React.useState(" ");
  const [loading, setLoading] = React.useState(false);
  const errorHandler = useErrorHandler();

  const appStore = useContext(AppContext);
  const projects = useContext(projectsContext);
  const notificationStore = useContext(NotificationContext);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const orgUuid = appStore.organization.uuid;
    if (name && orgUuid) {
      try {
        const project = await projects.createProject({ display: name });
        const projectName = project.name;
        if (projectName) {
          history.push(PROJECTS_PATH);
          props.dialogToggle();
        }
      } catch (error) {
        setNameError(errorHandler(error, {
          303: () => "Project name already exists.",
          400: () => "Malformed project name",
          401: () => "Unauthorized",
          403: (data: ErrorBody) => {
            notificationStore.enqueueAutohideSnackbar(
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{appStore.organization.name}</strong>
              </Typography>,
              "error",
            );

            props.dialogToggle();

            return " ";
          },
          404: () => "Organization not found",
          415: () => "Content-Type header is not application/json.",
          422: () => "Input is semantically incorrect.",
        }) as string);
      }
    } else {
      setNameError("Error");
    }
    setLoading(false);
  }

  return (
    <Dialog data-testid="project_create_dialog" open={props.dialogActive} onClose={props.dialogToggle} fullWidth={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Create Project</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" required={true} fullWidth={true}>
            <TextField
              data-testid="project_new_name"
              name="name"
              label="Project Name"
              helperText={nameError}
              error={nameError !== " "}
              disabled={loading}
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.dialogToggle} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button data-testid="project_new_button" type="submit" color="secondary" disabled={loading}>
            Create Project
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default withStyles(styles)(AddProject);
