import { ReactNode, useCallback, useContext } from "react";
import { ErrorBody, SeeOtherName, SeeOtherUuid } from "../../generatedApi/api";
import AccountContext from "../../stores/AccountStore";
import AppContext from "../../stores/AppStore";

type EmptyReporter = () => ReactNode;
type ErrorBodyReporter = (error: ErrorBody) => ReactNode;
type SeeOtherUuidReporter = (error: SeeOtherUuid) => ReactNode;
type SeeOtherNameReporter = (error: SeeOtherName) => ReactNode;
type ErrorReporter = SeeOtherNameReporter | SeeOtherUuidReporter | ErrorBodyReporter | EmptyReporter;

const ROLE_LACKS_PERMISSION = "role-lacks-permission";
const ORGANIZATION_REQUIRES_ADMIN = "organization-requires-admin";
const DELETE_PERSONAL_ORGANIZATION = "delete-personal-organization";
const LEAVE_PERSONAL_ORGANIZATION = "leave-personal-organization";
const UNVERIFIED_ACCOUNT_ACCEPT_INVITE = "unverified-account-accept-invite";
const PERSONAL_ORGANIZATION_INVITE = "personal-organization-invite";

export const mapErrorBody = (errorBody: ErrorBody) => {
  switch (errorBody.status) {
    case 403:
      const type = errorBody.type.split("/").pop();
      switch (type) {
        case ROLE_LACKS_PERMISSION:
          return "Role lacks permission";
        case ORGANIZATION_REQUIRES_ADMIN:
          return "Organization must have at least one 'admin' account remaining";
        case DELETE_PERSONAL_ORGANIZATION:
          return "Can't delete personal organization";
        case LEAVE_PERSONAL_ORGANIZATION:
          return "Can't leave personal organization";
        case UNVERIFIED_ACCOUNT_ACCEPT_INVITE:
          return "Can't accept invite if email is not verified";
        case PERSONAL_ORGANIZATION_INVITE:
          return "Can't invite to personal organization";
        default:
          return "Operation is forbidden";
      }
    default:
      return errorBody.detail;
  }
};

export const formatError = (error: any, errorMessages?: { [status: number]: ErrorReporter }) => {
  let errorMessage: ReactNode = "";
  if (error.response) {
    errorMessage =
      errorMessages && errorMessages[error.response.status]
        ? errorMessages[error.response.status](error.response.data)
        : "Unexpected Error!";
  } else if (error.request) {
    errorMessage = "The request was made but no response was received";
  } else {
    errorMessage = "Something happened in setting up the request that triggered an Error";
  }
  return errorMessage;
};

export const useErrorTrap = (): ((error: any) => ReactNode) => {
  const accountStore = useContext(AccountContext);
  const appStore = useContext(AppContext);

  const handler = useCallback(
    async (error: any) => {
      if (error.response) {
        // Log error
        // tslint:disable-next-line: no-console
        console.error(error.response);

        if (error.response.status === 401) {
          accountStore.clear();
          await appStore.logOut();
        }
      }
    },
    [accountStore, appStore],
  );

  return handler;
};

export const useErrorHandler = (): ((
  error: any,
  errorMessages?: {
    [status: number]: ErrorReporter;
  },
) => ReactNode) => {
  const accountStore = useContext(AccountContext);
  const appStore = useContext(AppContext);

  const handler = useCallback((error: any, errorMessages?: { [status: number]: ErrorReporter }): ReactNode => {
    let errorMessage: ReactNode = "";
    if (error.response) {
      // Log error
      // tslint:disable-next-line: no-console
      console.error(error.response);

      if (error.response.status === 401) {
        accountStore.clear();
        appStore.logOut();
      }

      errorMessage =
        errorMessages && errorMessages[error.response.status]
          ? errorMessages[error.response.status](error.response.data)
          : "Unexpected Error!";
    } else if (error.request) {
      errorMessage = "The request was made but no response was received";
    } else {
      errorMessage = "Something happened in setting up the request that triggered an Error";
    }
    return errorMessage;
  }, []);

  return handler;
};
