import { WithStyles, withStyles } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import AppContext from "../stores/AppStore";
import Header from "./Header/SiteHeader";
import { styles } from "./Styles/layout";

interface IProps extends WithStyles<typeof styles> {
  component: any;
  sidebar: any;
  path?: string;
  exact?: boolean;
}

const DashboardLayout = (props: IProps) => {
  const { component: Component, sidebar: Sidebar, classes, ...rest } = props;
  const appStore = useContext(AppContext);

  useEffect(() => {
    appStore.setLayout("dashboard");
  }, []);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className={classes.root}>
          <Header />
          <Sidebar {...matchProps} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Component {...matchProps} />
          </main>
        </div>
      )}
    />
  );
};

export default withStyles(styles)(DashboardLayout);
