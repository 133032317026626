interface IProps {
  if: number | false | true;
  children: any;
}

const Conditional = (props: IProps) => {
  return !!props.if && props.children;
};

export default Conditional;
