import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { match, RouteComponentProps, withRouter } from "react-router";
import AppContext from "../../stores/AppStore";
import Conditional from "../Conditional";
import { DASHBOARD_PATH, SIGN_IN_PATH } from "../Routes";
import AutoNotifier from "./AutoNotifier";
import OrgSelector from "./OrgSelector";
import SettingsMenu from "./SettingsMenu";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbarTitle: {
      flex: 1,
    },
    appLogo: {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  match: match<{ projectName: string }>;
}

const SiteHeader = observer((props: IProps) => {
  const { classes, location, history } = props;
  const appStore = useContext(AppContext);

  function isSettings() {
    return location.pathname.startsWith("/account/");
  }

  return (
    <React.Fragment>
      <AutoNotifier />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.toolbarTitle}>
            <Conditional if={!isSettings()}>
              <OrgSelector projectName={props.match.params.projectName} />
            </Conditional>
            <Conditional if={isSettings()}>
              <Button
                onClick={() => {
                  history.push(DASHBOARD_PATH);
                }}
                style={{ color: "white" }}
                data-testid="back-button"
              >
                <BackIcon /> Back to {appStore.organization.name}
              </Button>
            </Conditional>
          </div>
          <Conditional if={appStore.isLoggedIn()}>
            <SettingsMenu />
          </Conditional>
          <Conditional if={!appStore.isLoggedIn()}>
            <Button href={SIGN_IN_PATH} color="secondary" variant="outlined">
              Sign In
            </Button>
          </Conditional>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
});

export default withRouter(withStyles(styles)(SiteHeader));
