import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingOrgInvite2, NewOrgInvite } from "../generatedApi";
import { organizationApi } from "../services/api";

export class InvitesStore {
  // tslint:disable-next-line: variable-name
  @observable private _invites: ExistingOrgInvite2[] = [];
  @observable private organizationId: string = "";

  @computed get invites() {
    return this._invites;
  }
  set invites(invites: ExistingOrgInvite2[]) {
    this._invites = invites;
  }

  @action public setOrganization(organizationId: string) {
    if (this.organizationId !== organizationId) {
      this.invites = [];
      this.organizationId = organizationId;
    }
  }

  @action public async loadInvites() {
    try {
      const response = await organizationApi.listOrganizationInvites(this.organizationId);
      this.invites = response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async createInvite(orgInvite: NewOrgInvite) {
    try {
      await organizationApi.addInvite(this.organizationId, orgInvite);
      await this.loadInvites();
    } catch (err) {
      throw err;
    }
  }

  @action public async resendInvite(inviteId: string) {
    try {
      await organizationApi.resendOrganizationInvite(this.organizationId, inviteId);
    } catch (err) {
      throw err;
    }
  }

  @action public async deleteInvite(inviteId: string) {
    try {
      await organizationApi.removeOrganizationInvite(this.organizationId, inviteId);
    } catch (err) {
      throw err;
    }
    const idx = this._invites.findIndex(invite => invite.uuid === inviteId);
    if (idx > -1) {
      this._invites.splice(idx, 1);
    }
  }
}

const invitesStore = new InvitesStore();
export default createContext(invitesStore);
