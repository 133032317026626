import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { styles } from "../Styles/layout";

function BillingSettings() {
  return (
    <React.Fragment>
      <Typography data-testid="settings_section_title" variant="h5">
        Billing
      </Typography>
    </React.Fragment>
  );
}

export default withStyles(styles)(BillingSettings);
