import { Button, Divider, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router";
import logo from "../images/logo.svg";
import AccountContext from "../stores/AccountStore";
import { DASHBOARD_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from "./Routes";
import { marketing } from "./Styles/common";
import Link from "./UI/Link";

const styles = (theme: any) =>
  createStyles({
    ...marketing(theme),
    heroContent: {
      margin: "0 auto",
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
      lineHeight: 1.5,
    },
    logo: {
      width: "140px",
      display: "block",
      margin: `0 auto ${theme.spacing(6)}px`,
    },
    heroButton: {
      padding: theme.spacing(3),
      width: 380,
      margin: `${theme.spacing(1)}px auto 0`,
      display: "block",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    title: {
      lineHeight: 1.2,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down("xs")]: {
        fontSize: "3em",
      },
    },
    heroSubtitle: {
      backgroundColor: theme.palette.common.white,
      width: 420,
      margin: "-14px auto 0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        background: "none",
      },
    },
    divider: {
      width: 660,
      margin: "0 auto",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  } as any);

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const HomePage = observer((props: IProps) => {
  const { classes, history } = props;
  const accountStore = useContext(AccountContext);

  async function handleClick(to: string) {
    // XXX This implementation requires an *authenticated* API call to succeed
    try {
      await accountStore.loadAccount();
      history.push(DASHBOARD_PATH);
    } catch (error) {
      history.push(to);
    }
  }

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <div className={classes.heroContent}>
          <img alt="Nestor" src={logo} className={classes.logo} />
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom={true}
            className={classes.title}
          >
            <strong>Measure</strong> and <strong>understand</strong>
            <br />
            code performance
          </Typography>
          <Divider className={classes.divider} />
          <Typography
            component="h2"
            variant="body1"
            align="center"
            color="textSecondary"
            className={classes.heroSubtitle}
          >
            Move from benchmarking to profiling and optimizing
          </Typography>
          <Button
            className={classes.heroButton}
            variant="contained"
            color="primary"
            data-testid="get-started-now"
            onClick={() => handleClick(SIGN_UP_PATH)}
          >
            Get Started Now
          </Button>
          <Typography variant="body1" color="textSecondary" align="center" paragraph={true}>
            Already have an account?{" "}
            <Link to="#" onClick={() => handleClick(SIGN_IN_PATH)}>
              Sign in now
            </Link>
          </Typography>
        </div>
      </main>
    </React.Fragment>
  );
});

export default withStyles(styles)(HomePage);
