import { withStyles, WithStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Slider, { Mark } from "@material-ui/lab/Slider";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { DataPoint, Measurement, Metric } from "../../generatedApi";
import AccountContext from "../../stores/AccountStore";
import TrendContext, { TrendStore } from "../../stores/TrendStore";
import { styles } from "../Styles/layout";
import { StyledTrendGraph } from "../Trend/Index";
import Loader, { ProgressStyles } from "../UI/Loader";

interface IStatisticalMeasurement {
  metric: Metric;
  value: number;
  variance: number;
}

interface IStatisticalDataPoint {
  time: string;
  measurements: { [key: string]: IStatisticalMeasurement };
}

const FAKE_DATA: IStatisticalDataPoint[] = [
  {
    time: "yesterday",
    measurements: {
      foo: {
        metric: {
          name: "foo",
          units: "s",
        },
        value: 1,
        variance: 0.03,
      },
      bar: {
        metric: {
          name: "bar",
          units: "s",
        },
        value: 2,
        variance: 0.02,
      },
      baz: {
        metric: {
          name: "baz",
          units: "s",
        },
        value: 3,
        variance: 0.01,
      },
    },
  },
  {
    time: "today",
    measurements: {
      foo: {
        metric: {
          name: "foo",
          units: "s",
        },
        value: 2,
        variance: 0.06,
      },
      bar: {
        metric: {
          name: "bar",
          units: "s",
        },
        value: 4,
        variance: 0.04,
      },
      baz: {
        metric: {
          name: "baz",
          units: "s",
        },
        value: 6,
        variance: 0.02,
      },
    },
  },
];

const STANDARD_ERROR_AT_LEVEL_OF_SIGNIFICANCE = [0, 2.575, 2.335, 2.17, 2.055, 1.96, 1.885, 1.825, 1.755, 1.695, 1.645];

interface IToleranceTabProps extends WithStyles<typeof styles> {
  label: string;
  error: number;
  computeErrorBars: (value: number, variance: number) => number;
  // tslint:disable-next-line: variable-name
  handleErrorChange: (_event: ChangeEvent<{}>, value: number | number[]) => void;
}

const ToleranceTab = observer((props: IToleranceTabProps) => {
  const { classes, label, error, computeErrorBars, handleErrorChange } = props;
  const trendData = new TrendStore();

  React.useEffect(() => {
    loadTrendData(computeErrorBars);
  }, [error]);

  function loadTrendData(computeError: (value: number, variance: number) => number) {
    const dataPoints: DataPoint[] = [];

    FAKE_DATA.forEach(fakePoint => {
      const measurements: { [key: string]: Measurement } = {};
      for (const fakeName in fakePoint.measurements) {
        if (fakePoint.measurements.hasOwnProperty(fakeName)) {
          const fakeMeasurement = fakePoint.measurements[fakeName];
          measurements[fakeName] = {
            value: fakeMeasurement.value,
            metric: fakeMeasurement.metric,
            error: {
              max: fakeMeasurement.value + computeError(fakeMeasurement.value, fakeMeasurement.variance),
              min: fakeMeasurement.value - computeError(fakeMeasurement.value, fakeMeasurement.variance),
            },
          };
        }
      }
      const dataPoint: DataPoint = {
        time: fakePoint.time,
        measurements,
      };
      dataPoints.push(dataPoint);
    });

    trendData.dataPoints = dataPoints;
  }

  return (
    <React.Fragment>
      <TrendContext.Provider value={trendData}>
        <StyledTrendGraph classes={classes} />
      </TrendContext.Provider>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        <Slider
          className={classes.slider}
          defaultValue={error}
          marks={true}
          valueLabelDisplay="auto"
          valueLabelFormat={value => `${value}%`}
          aria-labelledby="discrete-slider"
          step={1}
          min={1}
          max={10}
          onChangeCommitted={handleErrorChange}
          data-testid="tolerance_slider"
        />
      </Typography>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {label}
      </Typography>
    </React.Fragment>
  );
});

interface IToleranceTabsProps extends WithStyles<typeof styles> {}

const ToleranceTabs = observer((props: IToleranceTabsProps) => {
  const { classes } = props;
  const [loading, setLoading] = React.useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [epsilon, setEpsilon] = React.useState(0);
  const [alpha, setAlpha] = React.useState(0);
  const accountStore = React.useContext(AccountContext);

  React.useEffect(() => {
    accountStore.loadAccount().then(() => {
      accountStore.loadTolerance().then(() => {
        setEpsilon(accountStore.tolerance.relative_difference);
        setAlpha(accountStore.tolerance.level_of_significance);
        setLoading(false);
      });
    });
  }, []);

  // tslint:disable-next-line: variable-name
  function computeWithRelativeError(value: number, _variance: number): number {
    return (value * epsilon) / 100;
  }

  // tslint:disable-next-line: variable-name
  function computeWithLevelOfSignificance(_value: number, variance: number): number {
    const standardError = STANDARD_ERROR_AT_LEVEL_OF_SIGNIFICANCE[alpha];
    return Math.sqrt(variance) * standardError;
  }

  // tslint:disable-next-line: variable-name
  function handleTabChange(_event: ChangeEvent<{}>, index: any) {
    setTabIndex(index);
  }

  // tslint:disable-next-line: variable-name
  async function handleEpsilonChange(_event: ChangeEvent<{}>, value: number | number[]) {
    const epsilon = value as number;
    await accountStore.setTolerance({ relative_difference: epsilon, level_of_significance: alpha });
    setEpsilon(epsilon);
  }

  // tslint:disable-next-line: variable-name
  async function handleAlphaChange(_event: ChangeEvent<{}>, value: number | number[]) {
    const alpha = value as number;
    await accountStore.setTolerance({ relative_difference: epsilon, level_of_significance: alpha });
    setAlpha(alpha);
  }

  // TODO get some help with the styling for centering and spacing elements
  return (
    <div className={classes.tabs}>
      <Typography variant="subtitle1" data-testid="settings_section_subtitle">
        Error Tolerance
      </Typography>
      <Tabs value={tabIndex} onChange={handleTabChange} centered={true}>
        <Tab label="Relative Difference" data-testid="settings_section_tab" />
        <Tab label="Level Of Significance" data-testid="settings_section_tab" />
      </Tabs>
      {tabIndex === 0 && (
        <Loader loaded={!loading} styles={ProgressStyles}>
          <ToleranceTab
            classes={classes}
            label="ε (%)"
            error={epsilon}
            computeErrorBars={computeWithRelativeError}
            handleErrorChange={handleEpsilonChange}
          />
        </Loader>
      )}
      {tabIndex === 1 && (
        <Loader loaded={!loading} styles={ProgressStyles}>
          <ToleranceTab
            classes={classes}
            label="α (%)"
            error={alpha}
            computeErrorBars={computeWithLevelOfSignificance}
            handleErrorChange={handleAlphaChange}
          />
        </Loader>
      )}
    </div>
  );
});

export default withStyles(styles)(ToleranceTabs);
