import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { FirebaseAuth } from "react-firebaseui";
import { Switch } from "react-router";
import { Route, Router } from "react-router-dom";
import history from "../stores/History";
import Dashboard from "./Dashboard";
import NotFound from "./Errors/NotFound";
import Footer from "./Footer/SiteFooter";
import HomePage from "./HomePage";
import DashboardLayout from "./Layouts";
import ManageMembers from "./Organizations/ManageMembers/Index";
import ManageOrgs from "./Organizations/ManageOrganizations/Index";
import Projects from "./Projects/ManageProjects/Index";
import ProjectSuites from "./Projects/Suites/Index";
import BillingSettings from "./Settings/BillingSettings";
import AccountSettings from "./Settings/Index";
import ManageApiKeys from "./Settings/ManageApiKeys/Index";
import ProjectsSideBar from "./SideBar/ProjectsSidebar";
import ProjectSuitesSidebar from "./SideBar/ProjectSuitesSidebar";
import SettingsSidebar from "./SideBar/SettingsSidebar";
import ForgotPassword from "./SignIn/ForgotPassword";
import SignIn from "./SignIn/Index";
import Credentials from "./SignUp/Credentials";
import FirstOrganization from "./SignUp/FirstOrganization";
import FirstProject from "./SignUp/FirstProject";
import ManageMembership from "./Suite/ManageMembership/Index";
import ManageSuites from "./Suite/ManageSuites/Index";
import Suite from "./Suite/Regression/Index";
import Trend from "./Trend/Index";

export const PROJECTS_PATH = "/projects";
export const PROJECT_SUITES_PATH = "/project/:projectName/suite";
export const SUITE_PATH = "/project/:projectName/suite/:suiteId";
export const MANAGE_SUITES_PATH = "/project/:projectName/suites/manage";
export const MANAGE_MEMBERSHIP_PATH = "/project/:projectName/membership/manage";
export const TREND_PATH = "/project/:projectName/suite/:suiteId/trend/:test";
export const SETTINGS_PATH = "/account/:accountId/settings";
export const API_KEYS_PATH = "/account/:accountId/api-keys";
export const BILLING_PATH = "/account/:accountId/billing";
export const MANAGE_ORGS_PATH = "/account/:accountId/orgs/manage";
export const ON_BOARDING_ORG_PATH = "/on-boarding/org";
export const ON_BOARDING_PROJECT_PATH = "/on-boarding/project";
export const DASHBOARD_PATH = "/dashboard";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const SIGN_IN_PATH = "/sign-in";
export const SIGN_UP_PATH = "/sign-up";
export const MANAGE_MEMBERS_PATH = "/account/:accountId/orgs/:orgId/manage";

const Routes = () => {
  return (
    <Router history={history}>
      <div className="wrapper">
        <CssBaseline />
        <Switch>
          <Route exact={true} path="/" component={HomePage} />
          <Route path={SIGN_UP_PATH} render={props => <Credentials AuthComponent={FirebaseAuth} {...props} />} />
          <Route path={ON_BOARDING_ORG_PATH} component={FirstOrganization} />
          <Route path={ON_BOARDING_PROJECT_PATH} component={FirstProject} />
          <Route path={SIGN_IN_PATH} render={props => <SignIn AuthComponent={FirebaseAuth} {...props} />} />
          <Route path={FORGOT_PASSWORD_PATH} component={ForgotPassword} />
          <DashboardLayout path={SETTINGS_PATH} sidebar={SettingsSidebar} component={AccountSettings} />
          <DashboardLayout path={API_KEYS_PATH} sidebar={SettingsSidebar} component={ManageApiKeys} />
          <DashboardLayout path={BILLING_PATH} sidebar={SettingsSidebar} component={BillingSettings} />
          <DashboardLayout path={MANAGE_ORGS_PATH} sidebar={SettingsSidebar} component={ManageOrgs} />
          <DashboardLayout path={MANAGE_MEMBERS_PATH} sidebar={SettingsSidebar} component={ManageMembers} />
          <DashboardLayout path={PROJECTS_PATH} sidebar={ProjectsSideBar} component={Projects} />
          <DashboardLayout path={DASHBOARD_PATH} sidebar={ProjectsSideBar} component={Dashboard} />
          <DashboardLayout exact={true} path={SUITE_PATH} sidebar={ProjectSuitesSidebar} component={Suite} />
          <DashboardLayout
            exact={true}
            path={MANAGE_MEMBERSHIP_PATH}
            sidebar={ProjectSuitesSidebar}
            component={ManageMembership}
          />
          <DashboardLayout
            exact={true}
            path={MANAGE_SUITES_PATH}
            sidebar={ProjectSuitesSidebar}
            component={ManageSuites}
          />
          <DashboardLayout
            exact={true}
            path={PROJECT_SUITES_PATH}
            sidebar={ProjectSuitesSidebar}
            component={ProjectSuites}
          />
          <DashboardLayout path={TREND_PATH} sidebar={ProjectSuitesSidebar} component={Trend} />
          <Route path="/" component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default Routes;
