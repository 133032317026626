import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import firebase from "firebase";
import { observer } from "mobx-react-lite";
import React, { FormEvent, useContext } from "react";
import { formatRoute } from "react-router-named-routes";
import Link from "../../components/UI/Link";
import ico from "../../images/icon.svg";
import { IAppProps } from "../../services/helpers/AppProps";
import AppContext from "../../stores/AppStore";
import OrgContext from "../../stores/OrganizationsStore";
import SnackbarContext from "../../stores/SnackBar";
import { firebaseAuthContext } from "../App";
import { FORGOT_PASSWORD_PATH, PROJECTS_PATH, SIGN_UP_PATH } from "../Routes";
import { form, marketing } from "../Styles/common";
import Snackbar from "../UI/SnackBar";

const styles = (theme: any) =>
  createStyles({
    ...marketing(theme),
    ...form(theme),
  } as any);

interface IProps extends WithStyles<typeof styles> {
  history: IAppProps["history"];
  AuthComponent: React.ComponentType<any>;
}

export const SignIn = observer((props: IProps) => {
  const [errorEmail, setErrorEmail] = React.useState(" ");
  const [errorPassword, setErrorPassword] = React.useState(" ");
  const firebaseAuth = useContext(firebaseAuthContext);
  const snackbarStore = useContext(SnackbarContext);
  const orgStore = useContext(OrgContext);
  const appStore = useContext(AppContext);
  const { classes, history, AuthComponent } = props;
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const PasswordErrorMessage = ((
    <span>
      The password you've written is incorrect.. <Link to={formatRoute(FORGOT_PASSWORD_PATH)}>Forgot password?</Link>
    </span>
  ) as unknown) as string;

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  async function userAuth(token: string) {
    try {
      await appStore.logIn(token);
      await orgStore.loadOrganizations();

      setLoading(false);
      const org = orgStore.organizations[0];
      appStore.setOrganization(org.name, org.uuid);
      history.push(PROJECTS_PATH);
    } catch (err) {
      setLoading(false);
      snackbarStore.setData({ message: "Permission denied. Please try again...", variant: "error" });
    }
  }

  const emailAuth = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrorEmail(" ");
    setErrorPassword(" ");
    setLoading(true);
    firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    // Authenticate with credentials
    const res = await firebaseAuth.signInWithEmailAndPassword(values.email, values.password).catch(error => {
      switch (error.code) {
        case "auth/invalid-email":
          setErrorEmail("Incorrect username. Please try again.");
        case "auth/user-disabled":
          setErrorEmail("Incorrect username. Please try again.");
        case "auth/user-not-found":
          setErrorEmail("Incorrect username. Please try again.");
        case "auth/wrong-password":
          setErrorPassword(PasswordErrorMessage);
          break;
        default:
          // tslint:disable-next-line: no-console
          console.error("Unrecognized error code: " + error.code);
      }
    });
    setLoading(false);

    if (res) {
      const email = res.user!.email;
      const domain = email!.substring(email!.lastIndexOf("@") + 1);
      res
        .user!.getIdToken(true)
        .then(idToken => {
          userAuth(idToken);
        })
        .catch(error => {
          snackbarStore.setData({ message: error, variant: "error" });
        });
    }
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult: { user: { email: any } }, redirectUrl: any) => {
        setLoading(true);
        firebaseAuth
          .currentUser!.getIdToken(/* forceRefresh */ true)
          .then(idToken => {
            userAuth(idToken);
          })
          .catch(error => {
            // Handle error
          });
        return false;
      },
    },
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.GithubAuthProvider.PROVIDER_ID],
  };

  return (
    <main>
      <Snackbar />
      <Link to="/">
        <img alt="Nestor" src={ico} className={classes.ico} />
      </Link>
      <Grid container={true} justify="center">
        <Grid item={true} xs={12} sm={12} md={8}>
          <Grid container={true} className={classes.container} spacing={0}>
            <Grid item={true} xs={12}>
              <Typography component="h1" className={classes.title}>
                Sign in to your account
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={6} className={classes.gutter}>
              <AuthComponent className={"firebaseui-auth-container"} uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
              {loading && <CircularProgress className={classes.progress} />}
              <Typography variant="body1" color="textSecondary" align="center" paragraph={true}>
                Don't have an account? <Link to={SIGN_UP_PATH}>Sign up now</Link>
              </Typography>
              <div className={classes.orWrapper}>
                <Typography className={classes.or}>OR</Typography>
              </div>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <form onSubmit={emailAuth} className={classes.form}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-email"
                    label="Email Address"
                    value={values.email}
                    onChange={handleChange("email")}
                    className={classes.authField}
                    helperText={errorEmail}
                    error={errorEmail !== " "}
                    disabled={loading}
                    variant="outlined"
                    data-testid="signin-email"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    id="adornment-password"
                    label="Password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    className={classes.authField}
                    helperText={errorPassword}
                    error={errorPassword !== " "}
                    disabled={loading}
                    variant="outlined"
                    data-testid="signin-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            className={classes.togglePassword}
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography variant="body2" align="right">
                    <Link to={formatRoute(FORGOT_PASSWORD_PATH)}>Forgot password?</Link>
                  </Typography>
                </FormControl>
                <FormControl fullWidth={true}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                    data-testid="signin-button"
                  >
                    Sign In
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
});

export default withStyles(styles)(SignIn);
