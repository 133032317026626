import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingOrgAccount, UpdateOrgAccount } from "../generatedApi";
import { organizationApi } from "../services/api";

export class MembersStore {
  // tslint:disable-next-line: variable-name
  @observable private _members: ExistingOrgAccount[] = [];
  @observable private organizationId: string = "";

  @computed get members() {
    return this._members;
  }
  set members(members: ExistingOrgAccount[]) {
    this._members = members;
  }

  @action public setOrganization(organizationId: string) {
    if (this.organizationId !== organizationId) {
      this._members = [];
      this.organizationId = organizationId;
    }
  }

  @action public async loadMembers() {
    try {
      const response = await organizationApi.listOrganizationAccounts(this.organizationId);
      this.members = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async deleteMember(memberId: string) {
    try {
      await organizationApi.removeOrganizationAccount(this.organizationId, memberId);
    } catch (err) {
      throw err;
    }
    const idx = this._members.findIndex(member => member.uuid === memberId);
    if (idx > -1) {
      this._members.splice(idx, 1);
    }
  }

  @action public async updateMember(memberId: string, updateMember: UpdateOrgAccount) {
    try {
      await organizationApi.updateOrganizationAccount(this.organizationId, memberId, updateMember);
      await this.loadMembers();
    } catch (err) {
      throw err;
    }
  }
}

const membersStore = new MembersStore();
export default createContext(membersStore);
