import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { formatRoute } from "react-router-named-routes";
import { ErrorBody, Project } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import { Scope } from "../../../services/helpers/scopes";
import AppContext from "../../../stores/AppStore";
import NotificationContext from "../../../stores/NotificationStore";
import ProjectsContext from "../../../stores/ProjectsStore";
import { PROJECT_SUITES_PATH } from "../../Routes";
import { styles } from "../../Styles/layout";
import DeleteDialog from "../../UI/DeleteDialog";
import NavLink from "../../UI/Link";
import { useDialog } from "../../UI/SimpleDialog";
import AddProject from "./AddProject";

// tslint:disable-next-line: prettier
interface IProps extends WithStyles<typeof styles> {}

const ProjectsList = observer((props: IProps) => {
  const [isAddDialogOpen, toggleAddDialog] = useDialog();
  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const [selectedProject, setSelectedProject] = useState("");
  const [copied, setCopied] = useState<[string, boolean]>(["", false]);
  const errorHandler = useErrorHandler();

  const projects = useContext(ProjectsContext);
  const notificationStore = useContext(NotificationContext);
  const appStore = useContext(AppContext);
  const { classes } = props;

  function deleteDialogToggle(selectedProjectId: string) {
    setSelectedProject(selectedProjectId);
    toggleDeleteDialog();
  }

  const handleCopy = (project: Project) => {
    if (project.name) {
      const textField = document.createElement("textarea");
      textField.innerText = project.name;
      const parentElement = document.getElementById("hidden");
      if (parentElement) {
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        parentElement.removeChild(textField);
        setCopied([project.name, true]);
        setTimeout(() => setCopied([project.name!, false]), 400);
      }
    }
  };

  function ProjectMenu(props: { showDelete: () => void }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const { showDelete } = props;

    function handleClick(event: any) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    function handleDelete() {
      showDelete();
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          data-testid="project_menu"
          aria-label="More"
          aria-owns={open ? "project-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="project-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={handleClose} disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}>
            Rename
          </MenuItem>
          <MenuItem
            data-testid="project_delete_option"
            onClick={() => handleDelete()}
            disabled={!appStore.scopes.includes(Scope.PROJECT_DELETE)}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AddProject dialogActive={isAddDialogOpen} dialogToggle={toggleAddDialog} />
      <Button
        data-testid="project_create_button"
        onClick={toggleAddDialog}
        variant="contained"
        color="primary"
        className={classes.floatRight}
        disabled={!appStore.scopes.includes(Scope.PROJECT_CREATE)}
      >
        Add New Project
      </Button>
      <Typography variant="h5" paragraph={true}>
        Projects
      </Typography>
      <div>
        <Table className={classes.table} aria-labelledby="Projects">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.borderRight} padding={"default"}>
                <Tooltip title="">
                  <TableSortLabel>PROJECTS</TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="left" padding={"default"}>
                <Tooltip title="">
                  <TableSortLabel>PROJECT KEY</TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="project_table">
            {projects.projects.map((project: Project, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell data-testid="project_table_display" align="left" className={classes.borderRight}>
                    <NavLink to={formatRoute(PROJECT_SUITES_PATH, { projectName: project.name! })}>
                      {project.display}
                    </NavLink>
                  </TableCell>
                  <TableCell data-testid="project_table_name" align="left">
                    {project.name!}&nbsp;&nbsp;
                    <Link
                      color="secondary"
                      onClick={() => handleCopy(project)}
                      style={{ cursor: "pointer", position: "absolute" }}
                    >
                      {copied[0] === project.name! && copied[1] ? "COPIED!" : "COPY"}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <ProjectMenu showDelete={() => deleteDialogToggle(project.name!)} />
                  </TableCell>
                  <DeleteDialog
                    active={selectedProject === project.name && isDeleteDialogOpen}
                    toggleActive={() => deleteDialogToggle("")}
                    name={project.display}
                    onDelete={async () => {
                      try {
                        await projects.deleteProject(project.name!);
                      } catch (error) {
                        const msg = errorHandler(error, {
                          403: (data: ErrorBody) => (
                            <Typography color="inherit">
                              {mapErrorBody(data)}: <strong>{project.name!}</strong>
                            </Typography>
                          ),
                        });
                        notificationStore.enqueueAutohideSnackbar(msg, "error");
                      }
                    }}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div id="hidden" />
      </div>
    </React.Fragment>
  );
});

export default withStyles(styles)(ProjectsList);
