import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useContext, useState } from "react";
import { match } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { ErrorBody, Suite } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import { Scope } from "../../../services/helpers/scopes";
import AppContext from "../../../stores/AppStore";
import NotificationContext, { VariantType } from "../../../stores/NotificationStore";
import SuitesContext from "../../../stores/SuiteStore";
import { SUITE_PATH } from "../../Routes";
import { styles } from "../../Styles/layout";
import DeleteDialog from "../../UI/DeleteDialog";
import NavLink from "../../UI/Link";
import { useDialog } from "../../UI/SimpleDialog";
import AddSuite from "./AddSuite";
import UpdateSuite from "./UpdateSuite";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string }>;
}

interface IMenuProps {
  showRename: () => void;
  showDelete: () => void;
}

const ManageSuites = observer((props: IProps) => {
  const [isUpdateDialogOpen, toggleUpdateDialog] = useDialog();
  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const [isAddDialogOpen, toggleAddDialog] = useDialog();
  const [selectedSuite, setSelectedSuite] = useState("");
  const errorHandler = useErrorHandler();

  const suitesStore = useContext(SuitesContext);
  const notificationStore = useContext(NotificationContext);
  const appStore = useContext(AppContext);
  const { classes, match } = props;

  function deleteDialogToggle(selectedSuiteId: string) {
    setSelectedSuite(selectedSuiteId);
    toggleDeleteDialog();
  }

  function updateDialogToggle(selectedSuiteId: string) {
    setSelectedSuite(selectedSuiteId);
    toggleUpdateDialog();
  }

  function notifyUser(msg: ReactNode, variant: VariantType) {
    notificationStore.enqueueAutohideSnackbar(msg, variant);
  }

  function SuitesMenu(props: IMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { showRename, showDelete } = props;
    const open = Boolean(anchorEl);

    function handleClick(event: any) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    function handleDelete() {
      showDelete();
      handleClose();
    }

    function handleRename() {
      showRename();
      handleClose();
    }

    return (
      <div>
        <IconButton
          data-testid="manage_suites_menu"
          aria-label="More"
          aria-owns={open ? "project-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="project-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            data-testid="manage_suites_rename_option"
            onClick={handleRename}
            disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}
          >
            Rename
          </MenuItem>
          <MenuItem
            data-testid="manage_suites_delete_option"
            onClick={handleDelete}
            disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AddSuite active={isAddDialogOpen} toggleActive={toggleAddDialog} projectName={match.params.projectName} />
      <Button
        data-testid="manage_suites_create_button"
        onClick={toggleAddDialog}
        variant="contained"
        color="primary"
        className={classes.floatRight}
        disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}
      >
        Add New Suite
      </Button>
      <Typography variant="h5" paragraph={true}>
        Manage Suites
      </Typography>
      <Table className={classes.table} aria-labelledby="Manage Suites">
        <TableHead>
          <TableRow>
            <TableCell align="left" padding={"default"}>
              <Tooltip title="">
                <TableSortLabel>SUITES</TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="manage_suites_table">
          {suitesStore.suites.map((suite: Suite, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">
                  <NavLink
                    to={{
                      pathname: formatRoute(SUITE_PATH, {
                        projectName: match.params.projectName,
                        suiteId: suite.uuid,
                      }),
                    }}
                  >
                    {suite.name}
                  </NavLink>
                </TableCell>
                <TableCell align="right">
                  <SuitesMenu
                    showRename={() => updateDialogToggle(suite.uuid!)}
                    showDelete={() => deleteDialogToggle(suite.uuid!)}
                  />
                </TableCell>
                <UpdateSuite
                  dialogActive={selectedSuite === suite.uuid && isUpdateDialogOpen}
                  dialogToggle={() => updateDialogToggle("")}
                  suite={suite}
                  onSuccess={(msg: ReactNode) => notifyUser(msg, "success")}
                  onFailure={(msg: ReactNode) => notifyUser(msg, "error")}
                  classes={classes}
                />
                <DeleteDialog
                  active={selectedSuite === suite.uuid && isDeleteDialogOpen}
                  toggleActive={() => deleteDialogToggle("")}
                  name={suite.name}
                  onDelete={async () => {
                    try {
                      await suitesStore.deleteSuite(suite.uuid ? suite.uuid : "");
                    } catch (error) {
                      const msg = errorHandler(error, {
                        403: (data: ErrorBody) => (
                          <Typography color="inherit">
                            {mapErrorBody(data)}: <strong>{suite.name}</strong>
                          </Typography>
                        ),
                      });
                      notificationStore.enqueueAutohideSnackbar(msg, "error");
                    }
                  }}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
});

export default withStyles(styles)(ManageSuites);
