import { Typography } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    emptyStateContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    emptyStateIcon: {
      color: `${theme.palette.grey[300]}`,
      fontSize: "160px",
      margin: 0,
    },
    emptyStateLabel: {
      fontSize: "25px",
      fontWeight: 500,
      lineHeight: "40px",
    },
    emptyStateDescription: {
      margin: "1em 0",
      fontSize: "16px",
      lineHeight: "24px",
    },
  });

interface IProps extends WithStyles<typeof styles> {
  Icon: React.ComponentType<SvgIconProps>;
  children?: any;
  label: string;
  description: JSX.Element;
}

const EmptyState = (props: IProps) => {
  const { classes, Icon, label, description, children } = props;

  return (
    <div className={classes.emptyStateContainer}>
      <Icon className={classes.emptyStateIcon} />
      <Typography variant="h5" className={classes.emptyStateLabel}>
        {label}
      </Typography>
      <Typography variant="body1" className={classes.emptyStateDescription} paragraph={true}>
        {description}
      </Typography>
      {children}
    </div>
  );
};
export default withStyles(styles)(EmptyState);
