import { WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { LegendPayload, LegendProps, Surface, Symbols } from "recharts";

const styles = {
  legend: {},
  legendItem: {
    marginRight: 10,
  },
};

interface IProps extends LegendProps, WithStyles<typeof styles> {
  enabled: (dataKey: string) => boolean;
  toggleEnabled: (dataKey: string) => void;
  formatDataKey: (value: any) => string;
}

const ToggleLegend = observer((props: IProps) => {
  const { classes, enabled, toggleEnabled, formatDataKey, payload } = props;

  return (
    <div className={classes.legend}>
      {payload!.map((entry: LegendPayload, index: number) => {
        const dataKey = formatDataKey(entry.value);
        const viewbox = { x: 0, y: 0, width: 10, height: 10 };

        return (
          <span
            key={index}
            className={classes.legendItem}
            onClick={() => toggleEnabled(dataKey)}
            style={{ color: enabled(dataKey) ? "#000" : "#AAA" }}
          >
            <Surface width={10} height={10} viewBox={viewbox}>
              <Symbols cx={5} cy={5} type="circle" size={50} fill={entry.color} />
              {!enabled(dataKey) && <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />}
            </Surface>
            <span>{dataKey}</span>
          </span>
        );
      })}
    </div>
  );
});

export default withStyles(styles)(ToggleLegend);
