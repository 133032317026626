import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { ReactNode } from "react";

const styles = (theme: Theme) =>
  createStyles({
    negative: {
      color: green[500],
      whiteSpace: "nowrap",
    },
    neutral: {
      whiteSpace: "nowrap",
    },
    positive: {
      color: red[500],
      whiteSpace: "nowrap",
    },
  });

interface ITrendData {
  children: ReactNode;
  delta: number;
  classes: any;
}

const TrendData = (props: ITrendData) => (
  <Typography
    className={
      props.delta > 0 ? props.classes.positive : props.delta < 0 ? props.classes.negative : props.classes.neutral
    }
  >
    {props.children ? props.children : "\u00A0"}
  </Typography>
);

export default withStyles(styles)(TrendData);
