import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { match, RouteComponentProps } from "react-router";
import { useErrorHandler } from "../../../services/helpers/ErrorHandler";
import TestMembershipsContext from "../../../stores/MembershipsStore";
import Conditional from "../../Conditional";
import { styles } from "../../Styles/layout";
import Instructions from "./Instructions";

interface IProps extends RouteComponentProps, WithStyles<typeof styles> {
  match: match<{ projectName: string }>;
}

export const SuitesOwnedByProject = observer((props: IProps) => {
  const membershipsStore = useContext(TestMembershipsContext);
  const [loaded, setLoaded] = useState(false);
  const errorHandler = useErrorHandler();
  const { match, location } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        membershipsStore.setProject(match.params.projectName);
        await membershipsStore.loadMemberships();
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoaded(true);
      }
    };
    fetchData();
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Conditional if={!loaded}>
        <CircularProgress />
      </Conditional>
      <Conditional if={loaded && membershipsStore.memberships.length === 0}>
        <Instructions projectName={match.params.projectName} />
      </Conditional>
      <Conditional if={loaded && membershipsStore.memberships.length > 0}>
        <Typography variant="h5">Project: {match.params.projectName}</Typography>
        <Typography variant="subtitle1" paragraph={true}>
          Acceptance Tests
        </Typography>
      </Conditional>
    </React.Fragment>
  );
});

export default withStyles(styles)(SuitesOwnedByProject);
