import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { ReactNode, useContext } from "react";
import { roleValueToName } from "../../../services/helpers/roleValueToName";

import { ExistingOrgAccount, ExistingOrganization } from "../../../generatedApi";
import { Scope } from "../../../services/helpers/scopes";
import NotificationContext, { VariantType } from "../../../stores/NotificationStore";
import { styles } from "../../Styles/layout";
import { useDialog } from "../../UI/SimpleDialog";
import RemoveUser from "./RemoveUser";
import UpdateUser from "./UpdateUser";

interface IMemberMenuProps extends WithStyles {
  org: ExistingOrganization;
  member: ExistingOrgAccount;
  scopes: string[];
}

const MemberMenu = (props: IMemberMenuProps) => {
  const [isRemoveDialogOpen, toggleRemoveDialog] = useDialog();
  const [isUpdateDialogOpen, toggleUpdateDialog] = useDialog();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const notificationStore = useContext(NotificationContext);
  const { classes, org, member, scopes } = props;

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleUpdate() {
    toggleUpdateDialog();
    setAnchorEl(null);
  }

  function handleRemove() {
    toggleRemoveDialog();
    setAnchorEl(null);
  }

  function notifyUser(msg: ReactNode, variant: VariantType) {
    notificationStore.enqueueAutohideSnackbar(msg, variant);
  }

  return (
    <React.Fragment>
      <IconButton data-testid="member_menu" aria-label="More" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={handleUpdate} disabled={!scopes.includes(Scope.ORG_ACCOUNT_UPDATE)}>
          Update
        </MenuItem>
        <MenuItem onClick={handleRemove} disabled={!scopes.includes(Scope.ORG_ACCOUNT_DELETE)}>
          Remove
        </MenuItem>
      </Menu>
      <RemoveUser
        dialogActive={isRemoveDialogOpen}
        dialogToggle={toggleRemoveDialog}
        org={org}
        member={member}
        onSuccess={(msg: ReactNode) => {
          notifyUser(msg, "success");
        }}
        onFailure={(msg: ReactNode) => {
          notifyUser(msg, "error");
        }}
        classes={classes}
      />
      <UpdateUser
        dialogActive={isUpdateDialogOpen}
        dialogToggle={toggleUpdateDialog}
        org={org}
        member={member}
        onSuccess={(msg: ReactNode) => {
          notifyUser(msg, "success");
        }}
        onFailure={(msg: ReactNode) => {
          notifyUser(msg, "error");
        }}
        classes={classes}
      />
    </React.Fragment>
  );
};

interface IListItemProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  member: ExistingOrgAccount;
  scopes: string[];
}

const MemberListItem = (props: IListItemProps) => {
  return (
    <React.Fragment>
      <TableCell align="left" className={props.classes.borderRight}>
        {props.member.name}
      </TableCell>
      <TableCell align="left">{roleValueToName(props.member.role)}</TableCell>
      <TableCell align="right">
        <MemberMenu org={props.org} member={props.member} classes={props.classes} scopes={props.scopes} />
      </TableCell>
    </React.Fragment>
  );
};

export default withStyles(styles)(MemberListItem);
