import { FormControl, InputBase, MenuItem, Select, withStyles, WithStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ExistingOrganization } from "../../generatedApi";
import { useErrorTrap } from "../../services/helpers/ErrorHandler";
import AppContext from "../../stores/AppStore";
import OrgContext from "../../stores/OrganizationsStore";
import ProjectContext from "../../stores/ProjectsStore";
import { PROJECTS_PATH } from "../Routes";
import { styles } from "../Styles/layout";
import Loader, { ProgressStyles } from "../UI/Loader";

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  projectName: string;
}

const OrgSelector = observer((props: IProps) => {
  const appStore = useContext(AppContext);
  const orgStore = useContext(OrgContext);
  const projectStore = useContext(ProjectContext);
  const trapError = useErrorTrap();
  const { history, projectName, location } = props;
  const [loading, setLoading] = useState(true);

  const BootstrapInput = withStyles(theme => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: 200,
      whiteSpace: "normal",
      paddingLeft: theme.spacing(2),
    },
  }))(InputBase);

  function handleChangeOrg(uuid: string) {
    const org = orgStore.getOrganization(uuid);
    appStore.setOrganization(org.name, org.uuid);
    history.push(PROJECTS_PATH);
  }

  const fetchOrganizations = async () => {
    try {
      await orgStore.loadOrganizations();
    } catch (error) {
      await trapError(error);
    }
  };

  const fetchProject = async () => {
    try {
      await projectStore.loadProject(projectName);
      const orgId = projectStore.getProject(projectName).organization_id;
      if (orgId) {
        const org = orgStore.getOrganization(orgId);
        appStore.setOrganization(org.name, org.uuid);
      }
    } catch (error) {
      trapError(error);
    }
  };

  const fetchState = () => {
    const orgs = fetchOrganizations();
    let project = Promise.resolve();

    if (location.pathname.startsWith("/project/")) {
      project = fetchProject();
    }

    Promise.all([orgs, project]).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchState();
  }, [appStore.organization.uuid]);

  return (
    <Loader loaded={!loading} styles={ProgressStyles} color="secondary">
      <FormControl>
        <Select
          value={appStore.organization.uuid}
          onChange={e => handleChangeOrg(e.target.value as string)}
          input={<BootstrapInput name="org" />}
          data-testid="header_org_select"
        >
          {orgStore.organizations.map((org: ExistingOrganization) => {
            return (
              <MenuItem key={org.uuid} value={org.uuid} data-testid="header_org_menu_item">
                {org.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Loader>
  );
});

export default withRouter(withStyles(styles)(OrgSelector));
