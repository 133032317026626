import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import firebase from "firebase";
import { observer } from "mobx-react-lite";
import React, { FormEvent, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import Link from "../../components/UI/Link";
import ico from "../../images/icon.svg";
import AccountContext from "../../stores/AccountStore";
import AppStore from "../../stores/AppStore";
import SnackbarContext from "../../stores/SnackBar";
import { firebaseAuthContext } from "../App";
import { ON_BOARDING_ORG_PATH, SIGN_IN_PATH } from "../Routes";
import { form, marketing } from "../Styles/common";
import Snackbar from "../UI/SnackBar";

const DEFAULT_TIMEOUT = 2500; // ms

const styles = (theme: any) =>
  createStyles({
    ...marketing(theme),
    ...form(theme),
  } as any);

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  AuthComponent: React.ComponentType<any>;
  timeout?: number;
}

export const SignUp = observer((props: IProps) => {
  const firebaseAuth = useContext(firebaseAuthContext);
  const snackbarStore = useContext(SnackbarContext);
  const accountStore = useContext(AccountContext);
  const appStore = useContext(AppStore);
  const { classes, history, AuthComponent, timeout } = props;
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [redirectTimeout, setRedirectTimeout] = React.useState();
  const [signInTimeout, setSignInTimeout] = React.useState();

  useEffect(() => {
    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout!);
      }

      if (signInTimeout) {
        clearTimeout(signInTimeout!);
      }
    };
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  function userExists() {
    snackbarStore.setData({ message: "Account already exists. Redirecting to Sign In...", variant: "warning" });
    setRedirectTimeout(
      setTimeout(
        () => {
          snackbarStore.close();
          history.push(SIGN_IN_PATH);
        },
        timeout ? timeout! : DEFAULT_TIMEOUT,
      ),
    );
  }

  async function signUpToken(name: string, token: string) {
    try {
      await accountStore.createAccount({ name, token });
      snackbarStore.setData({ message: "Successfully created account. Signing in...", variant: "success" });
      setSignInTimeout(
        setTimeout(
          async () => {
            try {
              await appStore.logIn(token);
              setLoading(false);
              history.push(ON_BOARDING_ORG_PATH);
            } catch (err) {
              setLoading(false);
              snackbarStore.setData({ message: "Permission denied. Please try again...", variant: "error" });
            }
          },
          timeout ? timeout! : DEFAULT_TIMEOUT,
        ),
      );
    } catch (error) {
      if (error.response.status === 303) {
        setLoading(false);
        userExists();
      } else {
        setLoading(false);
        snackbarStore.setData({ message: error.response.statusText, variant: "error" });
      }
    }
  }

  const emailAuth = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);
    firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

    // Create new user
    const res = await firebaseAuth.createUserWithEmailAndPassword(values.email, values.password).catch(error => {
      switch (error.code) {
        case "auth/email-already-in-use":
          userExists();
          break;
        case "auth/invalid-email":
          snackbarStore.setData({ message: "Malformed e-mail", variant: "error" });
          break;
        case "auth/weak-password":
          snackbarStore.setData({ message: "Please input a stronger password", variant: "error" });
          break;
        // This error shouldn't appear...
        default:
          // tslint:disable-next-line: no-console
          console.error("Unrecognized error code: " + error.code);
      }
      setLoading(false);
    });

    if (res) {
      if (!res.user!.emailVerified) {
        res.user!.sendEmailVerification();
      }
      const email = res.user!.email;
      // Update user profile name (in absence of supplied name)
      const name = email!;
      res
        .user!.updateProfile({
          displayName: name,
        })
        .then(
          () => {
            if (res) {
              res
                .user!.getIdToken(true)
                .then(idToken => {
                  signUpToken(name, idToken);
                })
                .catch(error => {
                  snackbarStore.setData({ message: error, variant: "error" });
                  setLoading(false);
                });
            }
          },
          error => {
            setLoading(false);
          },
        );
    }
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (
        authResult: { user: { email: any }; additionalUserInfo: { profile: { name: any } } },
        redirectUrl: any,
      ) => {
        setLoading(true);
        const email = authResult.user.email;
        const name = authResult.additionalUserInfo.profile.name || email;

        firebaseAuth
          .currentUser!.getIdToken(/* forceRefresh */ true)
          .then(idToken => {
            signUpToken(name, idToken);
          })
          .catch(error => {
            // Handle error
          });

        return false;
      },
    },
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.GithubAuthProvider.PROVIDER_ID],
  };

  return (
    <main>
      <Snackbar />
      <Link to="/">
        <img alt="Nestor" src={ico} className={classes.ico} />
      </Link>
      <Grid container={true} justify="center">
        <Grid item={true} xs={12} sm={12} md={8}>
          <Grid container={true} className={classes.container} spacing={0}>
            <Grid item={true} xs={12}>
              <Typography component="h1" className={classes.title}>
                Create an account
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={6} className={classes.gutter}>
              <AuthComponent
                className={"firebaseui-signup-container"}
                uiConfig={uiConfig}
                firebaseAuth={firebaseAuth}
              />
              {loading && <CircularProgress className={classes.progress} />}
              <Typography variant="body1" color="textSecondary" align="center" paragraph={true}>
                Already have an account? <Link to={SIGN_IN_PATH}>Sign in now</Link>
              </Typography>
              <div className={classes.orWrapper}>
                <Typography className={classes.or}>OR</Typography>
              </div>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <form onSubmit={emailAuth} className={classes.form}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-email"
                    label="Email Address"
                    value={values.email}
                    onChange={handleChange("email")}
                    className={classes.authField}
                    disabled={loading}
                    variant="outlined"
                    data-testid="signup-email"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    id="adornment-password"
                    label="Password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    className={classes.authField}
                    disabled={loading}
                    variant="outlined"
                    data-testid="signup-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            className={classes.togglePassword}
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                    data-testid="signup-button"
                  >
                    Sign Up
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
});

export default withStyles(styles)(SignUp);
