import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingKey, NewKey } from "../generatedApi";
import { accountApi } from "../services/api";

export class ApiKeysStore {
  @observable private apiKeysRegistry = observable.map<string, ExistingKey>();
  @observable private accountId: string = "";

  @computed public get apiKeys() {
    return Array.from(this.apiKeysRegistry.values());
  }
  public set apiKeys(apiKeys: ExistingKey[]) {
    this.apiKeysRegistry.clear();
    apiKeys.forEach(apiKey => {
      if (apiKey.uuid) {
        this.apiKeysRegistry.set(apiKey.uuid, apiKey);
      }
    });
  }

  public getApiKey(apiKeyId: string) {
    const key = this.apiKeysRegistry.get(apiKeyId);
    if (key) {
      return key;
    }
    return {
      uuid: "",
      name: "",
      value: "",
    };
  }

  @action public setAccount(accountId: string) {
    if (this.accountId !== accountId) {
      this.apiKeysRegistry.clear();
      this.accountId = accountId;
    }
  }

  @action public async loadApiKeys() {
    try {
      const response = await accountApi.listAccountKeys(this.accountId);
      this.apiKeys = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async createApiKey(apiKey: NewKey) {
    try {
      const response = await accountApi.accountKeyCreate(this.accountId, apiKey);
      if (response.data.uuid) {
        this.apiKeysRegistry.set(response.data.uuid, response.data);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async deleteApiKey(apiKeyId: string) {
    try {
      await accountApi.accountKeyDelete(this.accountId, apiKeyId);
      this.apiKeysRegistry.delete(apiKeyId);
    } catch (error) {
      throw error;
    }
  }
}

const apiKeysStore = new ApiKeysStore();
export default createContext(apiKeysStore);
