import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ErrorTolerance, ExistingAccount, ExistingOrgInvite, NewAccount } from "../generatedApi";
import { accountApi } from "../services/api";

export class AccountStore {
  // tslint:disable-next-line: variable-name
  @observable private _account: ExistingAccount = {
    email: "",
    email_verified: false,
    uuid: "",
    name: "",
  };
  // tslint:disable-next-line: variable-name
  @observable private _invites: ExistingOrgInvite[] = [];
  // tslint:disable-next-line: variable-name
  @observable private _tolerance: ErrorTolerance = {
    relative_difference: 0,
    level_of_significance: 0,
  };

  @computed public get account() {
    return this._account;
  }
  public set account(account: ExistingAccount) {
    this._account = account;
  }

  @computed public get invites() {
    return this._invites;
  }
  public set invites(invites: ExistingOrgInvite[]) {
    this._invites = invites;
  }

  @computed public get tolerance() {
    return this._tolerance;
  }
  public set tolerance(tolerance: ErrorTolerance) {
    this._tolerance = tolerance;
  }

  @computed public get isSignedIn() {
    return this.account.uuid !== "";
  }

  @action public async loadAccount() {
    try {
      const response = await accountApi.listAccounts();
      this.account = response.data[0];
    } catch (err) {
      throw err;
    }
  }

  @action public async loadInvites() {
    try {
      const response = await accountApi.listPendingInvites(this.account.uuid);
      this.invites = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async loadTolerance() {
    try {
      const response = await accountApi.getErrorTolerance(this._account.uuid);
      this.tolerance = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async createAccount(account: NewAccount) {
    try {
      const response = await accountApi.createAccount(account);
      this.account = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async acceptInvite(inviteId: string) {
    try {
      await accountApi.acceptOrganizationInvite(this.account.uuid, inviteId);
      await this.loadInvites();
    } catch (err) {
      throw err;
    }
  }

  @action public async rejectInvite(inviteId: string) {
    try {
      await accountApi.removeAccountInvite(this.account.uuid, inviteId);
      await this.loadInvites();
    } catch (err) {
      throw err;
    }
  }

  @action public async leaveOrganization(orgId: string) {
    try {
      await accountApi.leaveOrganization(this.account.uuid, orgId);
    } catch (err) {
      throw err;
    }
  }

  @action public async setTolerance(tolerance: ErrorTolerance) {
    await accountApi.removeErrorTolerance(this._account.uuid);
    await accountApi.setErrorTolerance(this._account.uuid, tolerance);
    this.tolerance = tolerance;
  }

  @action public clear() {
    this.account = {
      email: "",
      email_verified: false,
      uuid: "",
      name: "",
    };
    this.invites = [];
  }
}

const accountStore = new AccountStore();
export default createContext(accountStore);
