import { Button, Typography, WithStyles, withStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { formatError, useErrorTrap } from "../../services/helpers/ErrorHandler";
import AccountContext from "../../stores/AccountStore";
import NotificationContext from "../../stores/NotificationStore";
import { firebaseAuthContext } from "../App";
import { MANAGE_ORGS_PATH } from "../Routes";
import { styles } from "../Styles/layout";
import Notifier from "../UI/Notifier";

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const AutoNotifier = observer((props: IProps) => {
  const { history } = props;
  const accountStore = useContext(AccountContext);
  const notificationStore = useContext(NotificationContext);
  const firebaseAuth = useContext(firebaseAuthContext);
  const trapError = useErrorTrap();

  const fetchAccount = async () => {
    try {
      await accountStore.loadAccount();
      if (!accountStore.account.email_verified) {
        const action = (key: number) => (
          <Button
            onClick={() => {
              firebaseAuth.currentUser!.sendEmailVerification();
              notificationStore.removeSnackbar(key);
            }}
            color="inherit"
            data-testid="verification_email_resend_button"
          >
            {"Resend email"}
          </Button>
        );
        notificationStore.enqueueActionSnackbar(
          <Typography data-testid="verification_email_notification">
            You'll receive a verification email in <strong>{accountStore.account.email}</strong> to confirm your
            account. If you haven't received it within a few minutes, check your spam folder or resend the email.
          </Typography>,
          "info",
          action,
        );
      } else {
        await accountStore.loadInvites();
        if (accountStore.invites.length === 1) {
          const action = (key: number) => (
            <React.Fragment>
              <Button
                onClick={async () => {
                  notificationStore.removeSnackbar(key);
                  try {
                    await accountStore.rejectInvite(accountStore.invites[0].uuid);
                    notificationStore.enqueueAutohideSnackbar("Succesfully rejected organization", "success");
                  } catch (error) {
                    notificationStore.enqueueAutohideSnackbar(formatError(error), "error");
                  }
                }}
                color="inherit"
                data-testid="pending_invite_reject_button"
              >
                {"Reject"}
              </Button>
              <Button
                onClick={async () => {
                  notificationStore.removeSnackbar(key);
                  try {
                    await accountStore.acceptInvite(accountStore.invites[0].uuid);
                    notificationStore.enqueueAutohideSnackbar("Succesfully joined organization", "success");
                  } catch (error) {
                    notificationStore.enqueueAutohideSnackbar(formatError(error), "error");
                  }
                }}
                color="inherit"
                data-testid="pending_invite_accept_button"
              >
                {"Accept"}
              </Button>
            </React.Fragment>
          );
          notificationStore.enqueueActionSnackbar(
            <Typography color="inherit" data-testid="pending_invite_notification">
              You've been invited to join the organization, <strong>{accountStore.invites[0].name}</strong>
            </Typography>,
            "info",
            action,
          );
        } else if (accountStore.invites.length > 1) {
          const action = (key: number) => (
            <Button
              onClick={async () => {
                notificationStore.removeSnackbar(key);
                history.push(formatRoute(MANAGE_ORGS_PATH, { accountId: accountStore.account.uuid }));
              }}
              color="inherit"
              data-testid="manage_invites_button"
            >
              {"Manage invitations"}
            </Button>
          );
          notificationStore.enqueueActionSnackbar(
            <Typography color="inherit" data-testid="multiple_invite_notification">
              You've been invited to join <strong>{accountStore.invites.length}</strong> organizations.
            </Typography>,
            "info",
            action,
          );
        }
      }
    } catch (error) {
      await trapError(error);
    }
  };

  useEffect(() => {
    if (!accountStore.isSignedIn) {
      fetchAccount();
    }
    return () => {
      accountStore.clear();
      notificationStore.clear();
    };
  });

  return <Notifier />;
});

export default withRouter(withStyles(styles)(AutoNotifier));
