import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { DialogProps } from "@material-ui/core/Dialog";
import React, { useState } from "react";

interface IDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  actionButtonProps?: ButtonProps & { "data-testid"?: string };
  actionLabel?: string;
  onAction?: () => void;
  cancelLabel?: string;
  cancelButtonProps?: ButtonProps & { "data-testid"?: string };
  extraProps?: Partial<DialogProps>;
  children?: React.ReactElement | React.ReactElement[];
}

export const useDialog = (): [boolean, () => void] => {
  const [isOpen, setOpen] = useState(false);

  function toggle() {
    setOpen(!isOpen);
  }

  return [isOpen, toggle];
};

const DialogUI = (props: IDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} {...props.extraProps}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" {...props.cancelButtonProps}>
          {props.cancelLabel ? props.cancelLabel : "CANCEL"}
        </Button>
        <Button
          onClick={props.onAction ? props.onAction : props.onClose}
          color="secondary"
          {...props.actionButtonProps}
        >
          {props.actionLabel ? props.actionLabel : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUI;
