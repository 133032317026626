import history from "../../stores/History";
export function setDateRange(days: number) {
  if (days) {
    const event: Date = new Date();
    const dateTo: string = event.toISOString();

    event.setDate(event.getDate() - days);
    const dateFrom: string = event.toISOString();

    history.push(location.pathname + "?from=" + dateFrom + "&to=" + dateTo);
  } else {
    history.push(location.pathname);
  }
}

export function getDateRange(from?: string | string[] | null, to?: string | string[] | null, defaultRange?: number) {
  if ("string" === typeof from && "string" === typeof to) {
    const diffSeconds = (new Date(to).getTime() - new Date(from).getTime()) / 1000;
    const rangeDays = diffSeconds / 60 / 60 / 24;
    return { dateFrom: from, dateTo: to, range: Math.floor(rangeDays) };
  } else if (defaultRange) {
    const event: Date = new Date();
    const dateTo: string = event.toISOString();

    event.setDate(event.getDate() - defaultRange);
    const dateFrom: string = event.toISOString();
    return { dateFrom, dateTo, range: defaultRange };
  }
  return { dateFrom: undefined, dateTo: undefined, range: undefined };
}
