import { IconButton, Typography } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { ReactNode, useContext, useState } from "react";
import { formatRoute } from "react-router-named-routes";
import { ErrorBody, ExistingOrganization } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import { Scope } from "../../../services/helpers/scopes";
import AccountContext from "../../../stores/AccountStore";
import history from "../../../stores/History";
import NotificationContext, { VariantType } from "../../../stores/NotificationStore";
import OrganizationsContext, { OrganizationsStore } from "../../../stores/OrganizationsStore";
import { MANAGE_MEMBERS_PATH } from "../../Routes";
import { styles } from "../../Styles/layout";
import DeleteDialog from "../../UI/DeleteDialog";
import SimpleDialog, { useDialog } from "../../UI/SimpleDialog";
import UpdateOrganization from "./UpdateOrganization";

interface IMenuProps {
  showLeave: () => void;
  showRename: () => void;
  showDelete: () => void;
  showUsers: () => void;
  orgId: string;
}

const OrgMenu = (props: IMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { showLeave, showRename, showDelete, showUsers, orgId } = props;
  const [scopes, setScopes] = useState<string[]>([]);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleEnter() {
    setScopes(await OrganizationsStore.loadScopes(orgId));
  }

  function handleDelete() {
    showDelete();
    handleClose();
  }

  function handleLeave() {
    showLeave();
    handleClose();
  }

  function handleRename() {
    showRename();
    handleClose();
  }

  function handleUsers() {
    showUsers();
    handleClose();
  }

  return (
    <React.Fragment>
      <IconButton data-testid="org_menu" aria-label="More" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onEnter={handleEnter}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={handleLeave}>Leave</MenuItem>
        <MenuItem data-testid="org_rename_option" onClick={handleRename} disabled={!scopes.includes(Scope.ORG_UPDATE)}>
          Rename
        </MenuItem>
        <MenuItem onClick={handleUsers}>Manage users</MenuItem>
        <MenuItem data-testid="org_delete_option" onClick={handleDelete} disabled={!scopes.includes(Scope.ORG_DELETE)}>
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

interface IListItemProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  accountId: string;
}

// TODO: render members for each organization (classify them in admins and non-admins)
const OrganizationListItem = (props: IListItemProps) => {
  const [isLeaveDialogOpen, toggleLeaveDialog] = useDialog();
  const [isUpdateDialogOpen, toggleUpdateDialog] = useDialog();
  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const errorHandler = useErrorHandler();

  const notificationStore = useContext(NotificationContext);
  const orgStore = useContext(OrganizationsContext);
  const accountStore = useContext(AccountContext);
  const { org, classes } = props;

  function notifyUser(msg: ReactNode, variant: VariantType) {
    notificationStore.enqueueAutohideSnackbar(msg, variant);
  }

  async function handleLeave() {
    try {
      await accountStore.leaveOrganization(org.uuid);
      await orgStore.loadOrganizations();
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          Successfully left organization <strong>{org.name}</strong>.
        </Typography>,
        "success",
      );
      toggleLeaveDialog();
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(
        errorHandler(error, {
          403: (data: ErrorBody) => {
            return (
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{org.name}</strong>.
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  async function handleDelete() {
    toggleDeleteDialog();
    try {
      await orgStore.deleteOrganization(org.uuid);
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          Successfully deleted organization <strong>{org.name}</strong>.
        </Typography>,
        "success",
      );
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(
        errorHandler(error, {
          403: (data: ErrorBody) => {
            return (
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{org.name}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  return (
    <React.Fragment>
      <TableCell align="left" className={props.classes.borderRight}>
        {props.org.name}
      </TableCell>
      <TableCell align="left" />
      <TableCell align="right">
        <OrgMenu
          orgId={props.org.uuid}
          showLeave={toggleLeaveDialog}
          showRename={toggleUpdateDialog}
          showDelete={toggleDeleteDialog}
          showUsers={() => {
            history.push(formatRoute(MANAGE_MEMBERS_PATH, { accountId: props.accountId, orgId: props.org.uuid }));
          }}
        />
      </TableCell>
      <SimpleDialog
        open={isLeaveDialogOpen}
        onClose={toggleLeaveDialog}
        title={`Are you sure you want to leave the Organization ${props.org.name} ?`}
        actionLabel="LEAVE"
        onAction={handleLeave}
      >
        <DialogContentText>You will need to be re-invited if you wish to rejoin this organization.</DialogContentText>
      </SimpleDialog>
      <UpdateOrganization
        dialogActive={isUpdateDialogOpen}
        dialogToggle={toggleUpdateDialog}
        org={org}
        onSuccess={(msg: ReactNode) => notifyUser(msg, "success")}
        onFailure={(msg: ReactNode) => notifyUser(msg, "error")}
        classes={classes}
      />
      <DeleteDialog
        active={isDeleteDialogOpen}
        toggleActive={toggleDeleteDialog}
        name={`the Organization ${props.org.name}`}
        label="I understand that this will permanently delete the Organization and the Projects and Tests associated with it, without the ability to restore it."
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(OrganizationListItem);
