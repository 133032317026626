// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Nestor
 *  ## Public OpenAPI spec for the Nestor project.  ### Permissions  Permissions will depend on your `Role` with the `Organization` as follows   (see [add_invite](#/Organization/add_invite) and [update_organization_account](#/Organization/update_organization_account) for details):  `Viewer`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read-only__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read-only__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  `Editor`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read/write__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read-only__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  `Admin`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read/write__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read/write__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  ### Source Code  __This API is under active development, and the source repo is not public, yet.__
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: engineering@joylabs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  token?: string;
}
/**
 *
 * @export
 * @interface Bench
 */
export interface Bench {
  /**
   * Unique identifier
   * @type {string}
   * @memberof Bench
   */
  uuid?: string;
  /**
   *
   * @type {Run}
   * @memberof Bench
   */
  run: Run;
}
/**
 *
 * @export
 * @interface DataPoint
 */
export interface DataPoint {
  /**
   * Time at which the associated run was executed
   * @type {string}
   * @memberof DataPoint
   */
  time: string;
  /**
   * The measurements associated to a given test at the given time (run)
   * @type {{ [key: string]: Measurement; }}
   * @memberof DataPoint
   */
  measurements: { [key: string]: Measurement };
}
/**
 *
 * @export
 * @interface Duration
 */
export interface Duration {
  /**
   * Lower bound of the datetime range
   * @type {string}
   * @memberof Duration
   */
  start: string;
  /**
   * Upper bound of the datetime range
   * @type {string}
   * @memberof Duration
   */
  end: string;
}
/**
 *
 * @export
 * @interface ErrorBody
 */
export interface ErrorBody {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof ErrorBody
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof ErrorBody
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof ErrorBody
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof ErrorBody
   */
  detail: string;
}
/**
 *
 * @export
 * @interface ErrorTolerance
 */
export interface ErrorTolerance {
  /**
   * simple relative difference (ε), as a percentage
   * @type {number}
   * @memberof ErrorTolerance
   */
  relative_difference: number;
  /**
   * statistical level of significance (α), as a percentage
   * @type {number}
   * @memberof ErrorTolerance
   */
  level_of_significance: number;
}
/**
 *
 * @export
 * @interface ExistingAccount
 */
export interface ExistingAccount {
  /**
   * Full name
   * @type {string}
   * @memberof ExistingAccount
   */
  name: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingAccount
   */
  uuid: string;
  /**
   * Email
   * @type {string}
   * @memberof ExistingAccount
   */
  email: string;
  /**
   * Email verification flag
   * @type {boolean}
   * @memberof ExistingAccount
   */
  email_verified: boolean;
}
/**
 *
 * @export
 * @interface ExistingKey
 */
export interface ExistingKey {
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingKey
   */
  uuid: string;
  /**
   * User-friendly name
   * @type {string}
   * @memberof ExistingKey
   */
  name: string;
  /**
   * Key contents to present for authentication
   * @type {string}
   * @memberof ExistingKey
   */
  value: string;
}
/**
 *
 * @export
 * @interface ExistingOrgAccount
 */
export interface ExistingOrgAccount {
  /**
   * Full name
   * @type {string}
   * @memberof ExistingOrgAccount
   */
  name: string;
  /**
   * Email
   * @type {string}
   * @memberof ExistingOrgAccount
   */
  email: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingOrgAccount
   */
  uuid: string;
  /**
   * Role in organization
   * @type {string}
   * @memberof ExistingOrgAccount
   */
  role: string;
}
/**
 *
 * @export
 * @interface ExistingOrgInvite
 */
export interface ExistingOrgInvite {
  /**
   * Email
   * @type {string}
   * @memberof ExistingOrgInvite
   */
  email: string;
  /**
   * Name of organization
   * @type {string}
   * @memberof ExistingOrgInvite
   */
  name: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingOrgInvite
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface ExistingOrgInvite2
 */
export interface ExistingOrgInvite2 {
  /**
   * Email
   * @type {string}
   * @memberof ExistingOrgInvite2
   */
  email: string;
  /**
   * Role in organization
   * @type {string}
   * @memberof ExistingOrgInvite2
   */
  role: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingOrgInvite2
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface ExistingOrganization
 */
export interface ExistingOrganization {
  /**
   *
   * @type {string}
   * @memberof ExistingOrganization
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ExistingOrganization
   */
  email: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof ExistingOrganization
   */
  uuid: string;
  /**
   * Whether this is a personal or shared organization
   * @type {boolean}
   * @memberof ExistingOrganization
   */
  personal: boolean;
}
/**
 *
 * @export
 * @interface Measurement
 */
export interface Measurement {
  /**
   *
   * @type {Metric}
   * @memberof Measurement
   */
  metric: Metric;
  /**
   *
   * @type {number}
   * @memberof Measurement
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof Measurement
   */
  variance?: number;
  /**
   *
   * @type {MeasurementError}
   * @memberof Measurement
   */
  error: MeasurementError;
}
/**
 *
 * @export
 * @interface MeasurementError
 */
export interface MeasurementError {
  /**
   *
   * @type {number}
   * @memberof MeasurementError
   */
  min: number;
  /**
   *
   * @type {number}
   * @memberof MeasurementError
   */
  max: number;
}
/**
 *
 * @export
 * @interface Metric
 */
export interface Metric {
  /**
   *
   * @type {string}
   * @memberof Metric
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Metric
   */
  units: string;
}
/**
 *
 * @export
 * @interface NewAccount
 */
export interface NewAccount {
  /**
   * Full name
   * @type {string}
   * @memberof NewAccount
   */
  name: string;
  /**
   * JWT token
   * @type {string}
   * @memberof NewAccount
   */
  token: string;
}
/**
 *
 * @export
 * @interface NewKey
 */
export interface NewKey {
  /**
   * User-friendly name
   * @type {string}
   * @memberof NewKey
   */
  name: string;
}
/**
 *
 * @export
 * @interface NewOrgInvite
 */
export interface NewOrgInvite {
  /**
   * Email
   * @type {string}
   * @memberof NewOrgInvite
   */
  email: string;
  /**
   * Role in organization
   * @type {string}
   * @memberof NewOrgInvite
   */
  role: string;
}
/**
 *
 * @export
 * @interface NewOrganization
 */
export interface NewOrganization {
  /**
   *
   * @type {string}
   * @memberof NewOrganization
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NewOrganization
   */
  email: string;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   * Project\'s URL-safe name key
   * @type {string}
   * @memberof Project
   */
  name?: string;
  /**
   * Project\'s User-friendly diplay name
   * @type {string}
   * @memberof Project
   */
  display: string;
  /**
   * Project\'s owner organization uuid
   * @type {string}
   * @memberof Project
   */
  organization_id?: string;
}
/**
 *
 * @export
 * @interface Regression
 */
export interface Regression {
  /**
   *
   * @type {Duration}
   * @memberof Regression
   */
  duration: Duration;
  /**
   * Aggregates from all measurements associated with a suite
   * @type {{ [key: string]: Result; }}
   * @memberof Regression
   */
  results: { [key: string]: Result };
}
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {Test}
   * @memberof Result
   */
  latest?: Test;
  /**
   *
   * @type {Test}
   * @memberof Result
   */
  average?: Test;
  /**
   *
   * @type {Test}
   * @memberof Result
   */
  delta?: Test;
}
/**
 *
 * @export
 * @interface Run
 */
export interface Run {
  /**
   * Time at which this run was executed
   * @type {string}
   * @memberof Run
   */
  time: string;
  /**
   * All tests ran at one time
   * @type {{ [key: string]: Test; }}
   * @memberof Run
   */
  tests: { [key: string]: Test };
}
/**
 *
 * @export
 * @interface SeeOtherName
 */
export interface SeeOtherName {
  /**
   * Other name identifier
   * @type {string}
   * @memberof SeeOtherName
   */
  name: string;
}
/**
 *
 * @export
 * @interface SeeOtherUuid
 */
export interface SeeOtherUuid {
  /**
   * Other identifier
   * @type {string}
   * @memberof SeeOtherUuid
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface Suite
 */
export interface Suite {
  /**
   *
   * @type {string}
   * @memberof Suite
   */
  name: string;
  /**
   * Unique identifier
   * @type {string}
   * @memberof Suite
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface Test
 */
export interface Test {
  /**
   *
   * @type {string}
   * @memberof Test
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: Measurement; }}
   * @memberof Test
   */
  measurements: { [key: string]: Measurement };
}
/**
 *
 * @export
 * @interface TestSuiteMembership
 */
export interface TestSuiteMembership {
  /**
   *
   * @type {string}
   * @memberof TestSuiteMembership
   */
  test_name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TestSuiteMembership
   */
  suite_names: Array<string>;
}
/**
 *
 * @export
 * @interface Trend
 */
export interface Trend {
  /**
   *
   * @type {string}
   * @memberof Trend
   */
  test_name: string;
  /**
   * A collection of points (runs) associated to this test
   * @type {Array<DataPoint>}
   * @memberof Trend
   */
  data_points: Array<DataPoint>;
}
/**
 *
 * @export
 * @interface UpdateOrgAccount
 */
export interface UpdateOrgAccount {
  /**
   * Role in organization
   * @type {string}
   * @memberof UpdateOrgAccount
   */
  role?: string;
}
/**
 *
 * @export
 * @interface UpdateOrganization
 */
export interface UpdateOrganization {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganization
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateSuite
 */
export interface UpdateSuite {
  /**
   *
   * @type {string}
   * @memberof UpdateSuite
   */
  name?: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Accept invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptOrganizationInvite(account_id: string, invite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling acceptOrganizationInvite.",
        );
      }
      // verify required parameter 'invite_id' is not null or undefined
      if (invite_id === null || invite_id === undefined) {
        throw new RequiredError(
          "invite_id",
          "Required parameter invite_id was null or undefined when calling acceptOrganizationInvite.",
        );
      }
      const localVarPath = `/account/{account_id}/invite/{invite_id}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(invite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new API key for an account
     * @param {string} account_id The uuid of the account
     * @param {NewKey} NewKey The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyCreate(account_id: string, NewKey: NewKey, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling accountKeyCreate.",
        );
      }
      // verify required parameter 'NewKey' is not null or undefined
      if (NewKey === null || NewKey === undefined) {
        throw new RequiredError(
          "NewKey",
          "Required parameter NewKey was null or undefined when calling accountKeyCreate.",
        );
      }
      const localVarPath = `/account/{account_id}/key`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewKey" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(NewKey !== undefined ? NewKey : {})
        : NewKey || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete the api key.
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyDelete(account_id: string, key_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling accountKeyDelete.",
        );
      }
      // verify required parameter 'key_id' is not null or undefined
      if (key_id === null || key_id === undefined) {
        throw new RequiredError(
          "key_id",
          "Required parameter key_id was null or undefined when calling accountKeyDelete.",
        );
      }
      const localVarPath = `/account/{account_id}/key/{key_id}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)))
        .replace(`{${"key_id"}}`, encodeURIComponent(String(key_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create an account
     * @param {NewAccount} NewAccount The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(NewAccount: NewAccount, options: any = {}): RequestArgs {
      // verify required parameter 'NewAccount' is not null or undefined
      if (NewAccount === null || NewAccount === undefined) {
        throw new RequiredError(
          "NewAccount",
          "Required parameter NewAccount was null or undefined when calling createAccount.",
        );
      }
      const localVarPath = `/account`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewAccount" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(NewAccount !== undefined ? NewAccount : {})
        : NewAccount || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account api key
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountKey(account_id: string, key_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling getAccountKey.",
        );
      }
      // verify required parameter 'key_id' is not null or undefined
      if (key_id === null || key_id === undefined) {
        throw new RequiredError(
          "key_id",
          "Required parameter key_id was null or undefined when calling getAccountKey.",
        );
      }
      const localVarPath = `/account/{account_id}/key/{key_id}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)))
        .replace(`{${"key_id"}}`, encodeURIComponent(String(key_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getErrorTolerance(account_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling getErrorTolerance.",
        );
      }
      const localVarPath = `/account/{account_id}/tolerance`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Leave the organization
     * @param {string} account_id The uuid of the account
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveOrganization(account_id: string, org_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling leaveOrganization.",
        );
      }
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling leaveOrganization.",
        );
      }
      const localVarPath = `/account/{account_id}/org/{org_id}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)))
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of account api keys
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccountKeys(account_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling listAccountKeys.",
        );
      }
      const localVarPath = `/account/{account_id}/key`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of authenticated accounts
     * @param {string} [Cookie] Session cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccounts(Cookie?: string, options: any = {}): RequestArgs {
      const localVarPath = `/account`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      if (Cookie !== undefined && Cookie !== null) {
        localVarHeaderParameter["Cookie"] = String(Cookie);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of pending organization invites
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPendingInvites(account_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling listPendingInvites.",
        );
      }
      const localVarPath = `/account/{account_id}/invite`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAccountInvite(account_id: string, invite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling removeAccountInvite.",
        );
      }
      // verify required parameter 'invite_id' is not null or undefined
      if (invite_id === null || invite_id === undefined) {
        throw new RequiredError(
          "invite_id",
          "Required parameter invite_id was null or undefined when calling removeAccountInvite.",
        );
      }
      const localVarPath = `/account/{account_id}/invite/{invite_id}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(invite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove custom error tolerance (reverting to default)
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeErrorTolerance(account_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling removeErrorTolerance.",
        );
      }
      const localVarPath = `/account/{account_id}/tolerance`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {ErrorTolerance} [ErrorTolerance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setErrorTolerance(account_id: string, ErrorTolerance?: ErrorTolerance, options: any = {}): RequestArgs {
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling setErrorTolerance.",
        );
      }
      const localVarPath = `/account/{account_id}/tolerance`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(account_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ErrorTolerance" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(ErrorTolerance !== undefined ? ErrorTolerance : {})
        : ErrorTolerance || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Accept invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptOrganizationInvite(
      account_id: string,
      invite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).acceptOrganizationInvite(
        account_id,
        invite_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new API key for an account
     * @param {string} account_id The uuid of the account
     * @param {NewKey} NewKey The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyCreate(
      account_id: string,
      NewKey: NewKey,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingKey> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountKeyCreate(
        account_id,
        NewKey,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete the api key.
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyDelete(
      account_id: string,
      key_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountKeyDelete(
        account_id,
        key_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create an account
     * @param {NewAccount} NewAccount The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(
      NewAccount: NewAccount,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingAccount> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).createAccount(NewAccount, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get account api key
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountKey(
      account_id: string,
      key_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingKey> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getAccountKey(account_id, key_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getErrorTolerance(
      account_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorTolerance> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getErrorTolerance(account_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Leave the organization
     * @param {string} account_id The uuid of the account
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveOrganization(
      account_id: string,
      org_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).leaveOrganization(
        account_id,
        org_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of account api keys
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccountKeys(
      account_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingKey>> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listAccountKeys(account_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of authenticated accounts
     * @param {string} [Cookie] Session cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccounts(
      Cookie?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingAccount>> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listAccounts(Cookie, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of pending organization invites
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPendingInvites(
      account_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingOrgInvite>> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).listPendingInvites(account_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reject invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAccountInvite(
      account_id: string,
      invite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).removeAccountInvite(
        account_id,
        invite_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Remove custom error tolerance (reverting to default)
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeErrorTolerance(
      account_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).removeErrorTolerance(account_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Set custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {ErrorTolerance} [ErrorTolerance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setErrorTolerance(
      account_id: string,
      ErrorTolerance?: ErrorTolerance,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).setErrorTolerance(
        account_id,
        ErrorTolerance,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Accept invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptOrganizationInvite(account_id: string, invite_id: string, options?: any) {
      return AccountApiFp(configuration).acceptOrganizationInvite(account_id, invite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a new API key for an account
     * @param {string} account_id The uuid of the account
     * @param {NewKey} NewKey The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyCreate(account_id: string, NewKey: NewKey, options?: any) {
      return AccountApiFp(configuration).accountKeyCreate(account_id, NewKey, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete the api key.
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyDelete(account_id: string, key_id: string, options?: any) {
      return AccountApiFp(configuration).accountKeyDelete(account_id, key_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Create an account
     * @param {NewAccount} NewAccount The create payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(NewAccount: NewAccount, options?: any) {
      return AccountApiFp(configuration).createAccount(NewAccount, options)(axios, basePath);
    },
    /**
     *
     * @summary Get account api key
     * @param {string} account_id The uuid of the account
     * @param {string} key_id The uuid of the key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountKey(account_id: string, key_id: string, options?: any) {
      return AccountApiFp(configuration).getAccountKey(account_id, key_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getErrorTolerance(account_id: string, options?: any) {
      return AccountApiFp(configuration).getErrorTolerance(account_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Leave the organization
     * @param {string} account_id The uuid of the account
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveOrganization(account_id: string, org_id: string, options?: any) {
      return AccountApiFp(configuration).leaveOrganization(account_id, org_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of account api keys
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccountKeys(account_id: string, options?: any) {
      return AccountApiFp(configuration).listAccountKeys(account_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of authenticated accounts
     * @param {string} [Cookie] Session cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccounts(Cookie?: string, options?: any) {
      return AccountApiFp(configuration).listAccounts(Cookie, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of pending organization invites
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPendingInvites(account_id: string, options?: any) {
      return AccountApiFp(configuration).listPendingInvites(account_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Reject invite
     * @param {string} account_id The uuid of the account
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAccountInvite(account_id: string, invite_id: string, options?: any) {
      return AccountApiFp(configuration).removeAccountInvite(account_id, invite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Remove custom error tolerance (reverting to default)
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeErrorTolerance(account_id: string, options?: any) {
      return AccountApiFp(configuration).removeErrorTolerance(account_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Set custom error tolerance
     * @param {string} account_id The uuid of the account
     * @param {ErrorTolerance} [ErrorTolerance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setErrorTolerance(account_id: string, ErrorTolerance?: ErrorTolerance, options?: any) {
      return AccountApiFp(configuration).setErrorTolerance(account_id, ErrorTolerance, options)(axios, basePath);
    },
  };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   *
   * @summary Accept invite
   * @param {string} account_id The uuid of the account
   * @param {string} invite_id The uuid of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public acceptOrganizationInvite(account_id: string, invite_id: string, options?: any) {
    return AccountApiFp(this.configuration).acceptOrganizationInvite(account_id, invite_id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Create a new API key for an account
   * @param {string} account_id The uuid of the account
   * @param {NewKey} NewKey The create payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountKeyCreate(account_id: string, NewKey: NewKey, options?: any) {
    return AccountApiFp(this.configuration).accountKeyCreate(account_id, NewKey, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete the api key.
   * @param {string} account_id The uuid of the account
   * @param {string} key_id The uuid of the key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountKeyDelete(account_id: string, key_id: string, options?: any) {
    return AccountApiFp(this.configuration).accountKeyDelete(account_id, key_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create an account
   * @param {NewAccount} NewAccount The create payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public createAccount(NewAccount: NewAccount, options?: any) {
    return AccountApiFp(this.configuration).createAccount(NewAccount, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get account api key
   * @param {string} account_id The uuid of the account
   * @param {string} key_id The uuid of the key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccountKey(account_id: string, key_id: string, options?: any) {
    return AccountApiFp(this.configuration).getAccountKey(account_id, key_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get custom error tolerance
   * @param {string} account_id The uuid of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getErrorTolerance(account_id: string, options?: any) {
    return AccountApiFp(this.configuration).getErrorTolerance(account_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Leave the organization
   * @param {string} account_id The uuid of the account
   * @param {string} org_id The uuid of the org
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public leaveOrganization(account_id: string, org_id: string, options?: any) {
    return AccountApiFp(this.configuration).leaveOrganization(account_id, org_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of account api keys
   * @param {string} account_id The uuid of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public listAccountKeys(account_id: string, options?: any) {
    return AccountApiFp(this.configuration).listAccountKeys(account_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of authenticated accounts
   * @param {string} [Cookie] Session cookie
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public listAccounts(Cookie?: string, options?: any) {
    return AccountApiFp(this.configuration).listAccounts(Cookie, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of pending organization invites
   * @param {string} account_id The uuid of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public listPendingInvites(account_id: string, options?: any) {
    return AccountApiFp(this.configuration).listPendingInvites(account_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Reject invite
   * @param {string} account_id The uuid of the account
   * @param {string} invite_id The uuid of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public removeAccountInvite(account_id: string, invite_id: string, options?: any) {
    return AccountApiFp(this.configuration).removeAccountInvite(account_id, invite_id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Remove custom error tolerance (reverting to default)
   * @param {string} account_id The uuid of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public removeErrorTolerance(account_id: string, options?: any) {
    return AccountApiFp(this.configuration).removeErrorTolerance(account_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Set custom error tolerance
   * @param {string} account_id The uuid of the account
   * @param {ErrorTolerance} [ErrorTolerance]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public setErrorTolerance(account_id: string, ErrorTolerance?: ErrorTolerance, options?: any) {
    return AccountApiFp(this.configuration).setErrorTolerance(account_id, ErrorTolerance, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * BenchApi - axios parameter creator
 * @export
 */
export const BenchApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Submit a benchmark result
     * @param {string} project_name The name for the project will own this benchmark run
     * @param {Bench} Bench Benchmark payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBench(project_name: string, Bench: Bench, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling addBench.",
        );
      }
      // verify required parameter 'Bench' is not null or undefined
      if (Bench === null || Bench === undefined) {
        throw new RequiredError("Bench", "Required parameter Bench was null or undefined when calling addBench.");
      }
      const localVarPath = `/project/{project_name}/bench`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Bench" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(Bench !== undefined ? Bench : {}) : Bench || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a benchmark result by uuid
     * @param {string} project_name The name for the project that owns this benchmark run
     * @param {string} bench_id The uuid for the benchmark to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchById(project_name: string, bench_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getBenchById.",
        );
      }
      // verify required parameter 'bench_id' is not null or undefined
      if (bench_id === null || bench_id === undefined) {
        throw new RequiredError(
          "bench_id",
          "Required parameter bench_id was null or undefined when calling getBenchById.",
        );
      }
      const localVarPath = `/project/{project_name}/bench/{bench_id}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"bench_id"}}`, encodeURIComponent(String(bench_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BenchApi - functional programming interface
 * @export
 */
export const BenchApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Submit a benchmark result
     * @param {string} project_name The name for the project will own this benchmark run
     * @param {Bench} Bench Benchmark payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBench(
      project_name: string,
      Bench: Bench,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bench> {
      const localVarAxiosArgs = BenchApiAxiosParamCreator(configuration).addBench(project_name, Bench, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a benchmark result by uuid
     * @param {string} project_name The name for the project that owns this benchmark run
     * @param {string} bench_id The uuid for the benchmark to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchById(
      project_name: string,
      bench_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bench> {
      const localVarAxiosArgs = BenchApiAxiosParamCreator(configuration).getBenchById(project_name, bench_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BenchApi - factory interface
 * @export
 */
export const BenchApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Submit a benchmark result
     * @param {string} project_name The name for the project will own this benchmark run
     * @param {Bench} Bench Benchmark payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBench(project_name: string, Bench: Bench, options?: any) {
      return BenchApiFp(configuration).addBench(project_name, Bench, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a benchmark result by uuid
     * @param {string} project_name The name for the project that owns this benchmark run
     * @param {string} bench_id The uuid for the benchmark to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchById(project_name: string, bench_id: string, options?: any) {
      return BenchApiFp(configuration).getBenchById(project_name, bench_id, options)(axios, basePath);
    },
  };
};

/**
 * BenchApi - object-oriented interface
 * @export
 * @class BenchApi
 * @extends {BaseAPI}
 */
export class BenchApi extends BaseAPI {
  /**
   *
   * @summary Submit a benchmark result
   * @param {string} project_name The name for the project will own this benchmark run
   * @param {Bench} Bench Benchmark payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchApi
   */
  public addBench(project_name: string, Bench: Bench, options?: any) {
    return BenchApiFp(this.configuration).addBench(project_name, Bench, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a benchmark result by uuid
   * @param {string} project_name The name for the project that owns this benchmark run
   * @param {string} bench_id The uuid for the benchmark to be fetched
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchApi
   */
  public getBenchById(project_name: string, bench_id: string, options?: any) {
    return BenchApiFp(this.configuration).getBenchById(project_name, bench_id, options)(this.axios, this.basePath);
  }
}

/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new organization invite
     * @param {string} org_id The uuid of the org
     * @param {NewOrgInvite} NewOrgInvite Organization invite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addInvite(org_id: string, NewOrgInvite: NewOrgInvite, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError("org_id", "Required parameter org_id was null or undefined when calling addInvite.");
      }
      // verify required parameter 'NewOrgInvite' is not null or undefined
      if (NewOrgInvite === null || NewOrgInvite === undefined) {
        throw new RequiredError(
          "NewOrgInvite",
          "Required parameter NewOrgInvite was null or undefined when calling addInvite.",
        );
      }
      const localVarPath = `/org/{org_id}/invite`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewOrgInvite" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(NewOrgInvite !== undefined ? NewOrgInvite : {})
        : NewOrgInvite || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new organization
     * @param {NewOrganization} NewOrganization Organization suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganization(NewOrganization: NewOrganization, options: any = {}): RequestArgs {
      // verify required parameter 'NewOrganization' is not null or undefined
      if (NewOrganization === null || NewOrganization === undefined) {
        throw new RequiredError(
          "NewOrganization",
          "Required parameter NewOrganization was null or undefined when calling addOrganization.",
        );
      }
      const localVarPath = `/org`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewOrganization" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(NewOrganization !== undefined ? NewOrganization : {})
        : NewOrganization || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete an organization
     * @param {string} uuid The uuid for the organization to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganization(uuid: string, options: any = {}): RequestArgs {
      // verify required parameter 'uuid' is not null or undefined
      if (uuid === null || uuid === undefined) {
        throw new RequiredError(
          "uuid",
          "Required parameter uuid was null or undefined when calling deleteOrganization.",
        );
      }
      const localVarPath = `/org/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an organization by uuid
     * @param {string} uuid The uuid of the organization to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationById(uuid: string, options: any = {}): RequestArgs {
      // verify required parameter 'uuid' is not null or undefined
      if (uuid === null || uuid === undefined) {
        throw new RequiredError(
          "uuid",
          "Required parameter uuid was null or undefined when calling getOrganizationById.",
        );
      }
      const localVarPath = `/org/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of organization member accounts
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationAccounts(org_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling listOrganizationAccounts.",
        );
      }
      const localVarPath = `/org/{org_id}/account`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of organization member invites
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationInvites(org_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling listOrganizationInvites.",
        );
      }
      const localVarPath = `/org/{org_id}/invite`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizations(options: any = {}): RequestArgs {
      const localVarPath = `/org`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account\'s scopes on the given organization
     * @param {string} org_id The uuid of the organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listScopes(org_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError("org_id", "Required parameter org_id was null or undefined when calling listScopes.");
      }
      const localVarPath = `/org/{org_id}/scope`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove account from the organization
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationAccount(org_id: string, account_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling removeOrganizationAccount.",
        );
      }
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling removeOrganizationAccount.",
        );
      }
      const localVarPath = `/org/{org_id}/account/{account_id}`
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Rescind invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationInvite(org_id: string, invite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling removeOrganizationInvite.",
        );
      }
      // verify required parameter 'invite_id' is not null or undefined
      if (invite_id === null || invite_id === undefined) {
        throw new RequiredError(
          "invite_id",
          "Required parameter invite_id was null or undefined when calling removeOrganizationInvite.",
        );
      }
      const localVarPath = `/org/{org_id}/invite/{invite_id}`
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(invite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOrganizationInvite(org_id: string, invite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling resendOrganizationInvite.",
        );
      }
      // verify required parameter 'invite_id' is not null or undefined
      if (invite_id === null || invite_id === undefined) {
        throw new RequiredError(
          "invite_id",
          "Required parameter invite_id was null or undefined when calling resendOrganizationInvite.",
        );
      }
      const localVarPath = `/org/{org_id}/invite/{invite_id}/resend`
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(invite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update organization
     * @param {string} uuid The uuid for the organization to be updated
     * @param {UpdateOrganization} UpdateOrganization Update organization payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganization(uuid: string, UpdateOrganization: UpdateOrganization, options: any = {}): RequestArgs {
      // verify required parameter 'uuid' is not null or undefined
      if (uuid === null || uuid === undefined) {
        throw new RequiredError(
          "uuid",
          "Required parameter uuid was null or undefined when calling updateOrganization.",
        );
      }
      // verify required parameter 'UpdateOrganization' is not null or undefined
      if (UpdateOrganization === null || UpdateOrganization === undefined) {
        throw new RequiredError(
          "UpdateOrganization",
          "Required parameter UpdateOrganization was null or undefined when calling updateOrganization.",
        );
      }
      const localVarPath = `/org/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdateOrganization" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(UpdateOrganization !== undefined ? UpdateOrganization : {})
        : UpdateOrganization || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update member account role
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {UpdateOrgAccount} UpdateOrgAccount Update organization member payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganizationAccount(
      org_id: string,
      account_id: string,
      UpdateOrgAccount: UpdateOrgAccount,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling updateOrganizationAccount.",
        );
      }
      // verify required parameter 'account_id' is not null or undefined
      if (account_id === null || account_id === undefined) {
        throw new RequiredError(
          "account_id",
          "Required parameter account_id was null or undefined when calling updateOrganizationAccount.",
        );
      }
      // verify required parameter 'UpdateOrgAccount' is not null or undefined
      if (UpdateOrgAccount === null || UpdateOrgAccount === undefined) {
        throw new RequiredError(
          "UpdateOrgAccount",
          "Required parameter UpdateOrgAccount was null or undefined when calling updateOrganizationAccount.",
        );
      }
      const localVarPath = `/org/{org_id}/account/{account_id}`
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
        .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdateOrgAccount" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(UpdateOrgAccount !== undefined ? UpdateOrgAccount : {})
        : UpdateOrgAccount || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new organization invite
     * @param {string} org_id The uuid of the org
     * @param {NewOrgInvite} NewOrgInvite Organization invite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addInvite(
      org_id: string,
      NewOrgInvite: NewOrgInvite,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingOrgInvite2> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).addInvite(
        org_id,
        NewOrgInvite,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new organization
     * @param {NewOrganization} NewOrganization Organization suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganization(
      NewOrganization: NewOrganization,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingOrganization> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).addOrganization(
        NewOrganization,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete an organization
     * @param {string} uuid The uuid for the organization to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganization(
      uuid: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).deleteOrganization(uuid, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get an organization by uuid
     * @param {string} uuid The uuid of the organization to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationById(
      uuid: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistingOrganization> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).getOrganizationById(uuid, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of organization member accounts
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationAccounts(
      org_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingOrgAccount>> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).listOrganizationAccounts(
        org_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of organization member invites
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationInvites(
      org_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingOrgInvite2>> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).listOrganizationInvites(
        org_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizations(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExistingOrganization>> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).listOrganizations(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get account\'s scopes on the given organization
     * @param {string} org_id The uuid of the organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listScopes(
      org_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).listScopes(org_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Remove account from the organization
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationAccount(
      org_id: string,
      account_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).removeOrganizationAccount(
        org_id,
        account_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Rescind invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationInvite(
      org_id: string,
      invite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).removeOrganizationInvite(
        org_id,
        invite_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Resend invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOrganizationInvite(
      org_id: string,
      invite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).resendOrganizationInvite(
        org_id,
        invite_id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update organization
     * @param {string} uuid The uuid for the organization to be updated
     * @param {UpdateOrganization} UpdateOrganization Update organization payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganization(
      uuid: string,
      UpdateOrganization: UpdateOrganization,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).updateOrganization(
        uuid,
        UpdateOrganization,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update member account role
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {UpdateOrgAccount} UpdateOrgAccount Update organization member payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganizationAccount(
      org_id: string,
      account_id: string,
      UpdateOrgAccount: UpdateOrgAccount,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).updateOrganizationAccount(
        org_id,
        account_id,
        UpdateOrgAccount,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Create a new organization invite
     * @param {string} org_id The uuid of the org
     * @param {NewOrgInvite} NewOrgInvite Organization invite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addInvite(org_id: string, NewOrgInvite: NewOrgInvite, options?: any) {
      return OrganizationApiFp(configuration).addInvite(org_id, NewOrgInvite, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a new organization
     * @param {NewOrganization} NewOrganization Organization suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganization(NewOrganization: NewOrganization, options?: any) {
      return OrganizationApiFp(configuration).addOrganization(NewOrganization, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete an organization
     * @param {string} uuid The uuid for the organization to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganization(uuid: string, options?: any) {
      return OrganizationApiFp(configuration).deleteOrganization(uuid, options)(axios, basePath);
    },
    /**
     *
     * @summary Get an organization by uuid
     * @param {string} uuid The uuid of the organization to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationById(uuid: string, options?: any) {
      return OrganizationApiFp(configuration).getOrganizationById(uuid, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of organization member accounts
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationAccounts(org_id: string, options?: any) {
      return OrganizationApiFp(configuration).listOrganizationAccounts(org_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of organization member invites
     * @param {string} org_id The uuid of the org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizationInvites(org_id: string, options?: any) {
      return OrganizationApiFp(configuration).listOrganizationInvites(org_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrganizations(options?: any) {
      return OrganizationApiFp(configuration).listOrganizations(options)(axios, basePath);
    },
    /**
     *
     * @summary Get account\'s scopes on the given organization
     * @param {string} org_id The uuid of the organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listScopes(org_id: string, options?: any) {
      return OrganizationApiFp(configuration).listScopes(org_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Remove account from the organization
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationAccount(org_id: string, account_id: string, options?: any) {
      return OrganizationApiFp(configuration).removeOrganizationAccount(org_id, account_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Rescind invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationInvite(org_id: string, invite_id: string, options?: any) {
      return OrganizationApiFp(configuration).removeOrganizationInvite(org_id, invite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Resend invite
     * @param {string} org_id The uuid of the org
     * @param {string} invite_id The uuid of the invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOrganizationInvite(org_id: string, invite_id: string, options?: any) {
      return OrganizationApiFp(configuration).resendOrganizationInvite(org_id, invite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Update organization
     * @param {string} uuid The uuid for the organization to be updated
     * @param {UpdateOrganization} UpdateOrganization Update organization payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganization(uuid: string, UpdateOrganization: UpdateOrganization, options?: any) {
      return OrganizationApiFp(configuration).updateOrganization(uuid, UpdateOrganization, options)(axios, basePath);
    },
    /**
     *
     * @summary Update member account role
     * @param {string} org_id The uuid of the org
     * @param {string} account_id The uuid of the account
     * @param {UpdateOrgAccount} UpdateOrgAccount Update organization member payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganizationAccount(org_id: string, account_id: string, UpdateOrgAccount: UpdateOrgAccount, options?: any) {
      return OrganizationApiFp(configuration).updateOrganizationAccount(org_id, account_id, UpdateOrgAccount, options)(
        axios,
        basePath,
      );
    },
  };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
  /**
   *
   * @summary Create a new organization invite
   * @param {string} org_id The uuid of the org
   * @param {NewOrgInvite} NewOrgInvite Organization invite payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public addInvite(org_id: string, NewOrgInvite: NewOrgInvite, options?: any) {
    return OrganizationApiFp(this.configuration).addInvite(org_id, NewOrgInvite, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new organization
   * @param {NewOrganization} NewOrganization Organization suite payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public addOrganization(NewOrganization: NewOrganization, options?: any) {
    return OrganizationApiFp(this.configuration).addOrganization(NewOrganization, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete an organization
   * @param {string} uuid The uuid for the organization to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public deleteOrganization(uuid: string, options?: any) {
    return OrganizationApiFp(this.configuration).deleteOrganization(uuid, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get an organization by uuid
   * @param {string} uuid The uuid of the organization to be retrieved
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getOrganizationById(uuid: string, options?: any) {
    return OrganizationApiFp(this.configuration).getOrganizationById(uuid, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of organization member accounts
   * @param {string} org_id The uuid of the org
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public listOrganizationAccounts(org_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).listOrganizationAccounts(org_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of organization member invites
   * @param {string} org_id The uuid of the org
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public listOrganizationInvites(org_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).listOrganizationInvites(org_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of organizations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public listOrganizations(options?: any) {
    return OrganizationApiFp(this.configuration).listOrganizations(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get account\'s scopes on the given organization
   * @param {string} org_id The uuid of the organization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public listScopes(org_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).listScopes(org_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Remove account from the organization
   * @param {string} org_id The uuid of the org
   * @param {string} account_id The uuid of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public removeOrganizationAccount(org_id: string, account_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).removeOrganizationAccount(org_id, account_id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Rescind invite
   * @param {string} org_id The uuid of the org
   * @param {string} invite_id The uuid of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public removeOrganizationInvite(org_id: string, invite_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).removeOrganizationInvite(org_id, invite_id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Resend invite
   * @param {string} org_id The uuid of the org
   * @param {string} invite_id The uuid of the invite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public resendOrganizationInvite(org_id: string, invite_id: string, options?: any) {
    return OrganizationApiFp(this.configuration).resendOrganizationInvite(org_id, invite_id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Update organization
   * @param {string} uuid The uuid for the organization to be updated
   * @param {UpdateOrganization} UpdateOrganization Update organization payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public updateOrganization(uuid: string, UpdateOrganization: UpdateOrganization, options?: any) {
    return OrganizationApiFp(this.configuration).updateOrganization(uuid, UpdateOrganization, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Update member account role
   * @param {string} org_id The uuid of the org
   * @param {string} account_id The uuid of the account
   * @param {UpdateOrgAccount} UpdateOrgAccount Update organization member payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public updateOrganizationAccount(
    org_id: string,
    account_id: string,
    UpdateOrgAccount: UpdateOrgAccount,
    options?: any,
  ) {
    return OrganizationApiFp(this.configuration).updateOrganizationAccount(
      org_id,
      account_id,
      UpdateOrgAccount,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {Project} Project Project payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProject(org_id: string, Project: Project, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError("org_id", "Required parameter org_id was null or undefined when calling addProject.");
      }
      // verify required parameter 'Project' is not null or undefined
      if (Project === null || Project === undefined) {
        throw new RequiredError("Project", "Required parameter Project was null or undefined when calling addProject.");
      }
      const localVarPath = `/org/{org_id}/project`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Project" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(Project !== undefined ? Project : {})
        : Project || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {string} project_name The name for the project that will be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProjectToOrg(org_id: string, project_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError(
          "org_id",
          "Required parameter org_id was null or undefined when calling addProjectToOrg.",
        );
      }
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling addProjectToOrg.",
        );
      }
      const localVarPath = `/org/{org_id}/project/{project_name}`
        .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check a project by name
     * @param {string} project_name The name of the project to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkProjectByName(project_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling checkProjectByName.",
        );
      }
      const localVarPath = `/project/{project_name}`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "HEAD", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a project
     * @param {string} project_name The name for the project to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(project_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling deleteProject.",
        );
      }
      const localVarPath = `/project/{project_name}`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a project by name
     * @param {string} project_name The name of the project to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectByName(project_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getProjectByName.",
        );
      }
      const localVarPath = `/project/{project_name}`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve all the tests, with their corresponding suite membership (if any), that exist in the given project, optionally filtered by suite name
     * @param {string} project_name The name of the project that owns the tests to be retrieved
     * @param {string} [suite_name] Suite name parameter to filter the test results by, returning only those tests that are associated to the given suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTestMembership(project_name: string, suite_name?: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getTestMembership.",
        );
      }
      const localVarPath = `/project/{project_name}/test`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      if (suite_name !== undefined) {
        localVarQueryParameter["suite_name"] = suite_name;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a trend from the specified test_name, for all the existing runs. If a date range is specified, returns only the runs inside that range
     * @param {string} project_name The name for the project that owns this test
     * @param {string} test_name The test name from which to return the Trend for all the existing runs
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range, can\&#39;t be older than from parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrend(project_name: string, test_name: string, from?: string, to?: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getTrend.",
        );
      }
      // verify required parameter 'test_name' is not null or undefined
      if (test_name === null || test_name === undefined) {
        throw new RequiredError(
          "test_name",
          "Required parameter test_name was null or undefined when calling getTrend.",
        );
      }
      const localVarPath = `/project/{project_name}/trend/{test_name}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"test_name"}}`, encodeURIComponent(String(test_name)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of projects owned by an organization
     * @param {string} org_id The uuid of the organization that owns the projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(org_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'org_id' is not null or undefined
      if (org_id === null || org_id === undefined) {
        throw new RequiredError("org_id", "Required parameter org_id was null or undefined when calling listProjects.");
      }
      const localVarPath = `/org/{org_id}/project`.replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {Project} Project Project payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProject(
      org_id: string,
      Project: Project,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).addProject(org_id, Project, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {string} project_name The name for the project that will be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProjectToOrg(
      org_id: string,
      project_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).addProjectToOrg(
        org_id,
        project_name,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Check a project by name
     * @param {string} project_name The name of the project to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkProjectByName(
      project_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).checkProjectByName(project_name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a project
     * @param {string} project_name The name for the project to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(
      project_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).deleteProject(project_name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a project by name
     * @param {string} project_name The name of the project to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectByName(
      project_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectByName(project_name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Retrieve all the tests, with their corresponding suite membership (if any), that exist in the given project, optionally filtered by suite name
     * @param {string} project_name The name of the project that owns the tests to be retrieved
     * @param {string} [suite_name] Suite name parameter to filter the test results by, returning only those tests that are associated to the given suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTestMembership(
      project_name: string,
      suite_name?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestSuiteMembership>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTestMembership(
        project_name,
        suite_name,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Retrieve a trend from the specified test_name, for all the existing runs. If a date range is specified, returns only the runs inside that range
     * @param {string} project_name The name for the project that owns this test
     * @param {string} test_name The test name from which to return the Trend for all the existing runs
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range, can\&#39;t be older than from parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrend(
      project_name: string,
      test_name: string,
      from?: string,
      to?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trend> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTrend(
        project_name,
        test_name,
        from,
        to,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of projects owned by an organization
     * @param {string} org_id The uuid of the organization that owns the projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(
      org_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listProjects(org_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {Project} Project Project payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProject(org_id: string, Project: Project, options?: any) {
      return ProjectApiFp(configuration).addProject(org_id, Project, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a new project owned by an organization
     * @param {string} org_id The uuid of the organization that will own the new project
     * @param {string} project_name The name for the project that will be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProjectToOrg(org_id: string, project_name: string, options?: any) {
      return ProjectApiFp(configuration).addProjectToOrg(org_id, project_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Check a project by name
     * @param {string} project_name The name of the project to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkProjectByName(project_name: string, options?: any) {
      return ProjectApiFp(configuration).checkProjectByName(project_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a project
     * @param {string} project_name The name for the project to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(project_name: string, options?: any) {
      return ProjectApiFp(configuration).deleteProject(project_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a project by name
     * @param {string} project_name The name of the project to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectByName(project_name: string, options?: any) {
      return ProjectApiFp(configuration).getProjectByName(project_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Retrieve all the tests, with their corresponding suite membership (if any), that exist in the given project, optionally filtered by suite name
     * @param {string} project_name The name of the project that owns the tests to be retrieved
     * @param {string} [suite_name] Suite name parameter to filter the test results by, returning only those tests that are associated to the given suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTestMembership(project_name: string, suite_name?: string, options?: any) {
      return ProjectApiFp(configuration).getTestMembership(project_name, suite_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Retrieve a trend from the specified test_name, for all the existing runs. If a date range is specified, returns only the runs inside that range
     * @param {string} project_name The name for the project that owns this test
     * @param {string} test_name The test name from which to return the Trend for all the existing runs
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range, can\&#39;t be older than from parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrend(project_name: string, test_name: string, from?: string, to?: string, options?: any) {
      return ProjectApiFp(configuration).getTrend(project_name, test_name, from, to, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of projects owned by an organization
     * @param {string} org_id The uuid of the organization that owns the projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(org_id: string, options?: any) {
      return ProjectApiFp(configuration).listProjects(org_id, options)(axios, basePath);
    },
  };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
  /**
   *
   * @summary Create a new project owned by an organization
   * @param {string} org_id The uuid of the organization that will own the new project
   * @param {Project} Project Project payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public addProject(org_id: string, Project: Project, options?: any) {
    return ProjectApiFp(this.configuration).addProject(org_id, Project, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new project owned by an organization
   * @param {string} org_id The uuid of the organization that will own the new project
   * @param {string} project_name The name for the project that will be created
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public addProjectToOrg(org_id: string, project_name: string, options?: any) {
    return ProjectApiFp(this.configuration).addProjectToOrg(org_id, project_name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Check a project by name
   * @param {string} project_name The name of the project to be checked
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public checkProjectByName(project_name: string, options?: any) {
    return ProjectApiFp(this.configuration).checkProjectByName(project_name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a project
   * @param {string} project_name The name for the project to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public deleteProject(project_name: string, options?: any) {
    return ProjectApiFp(this.configuration).deleteProject(project_name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a project by name
   * @param {string} project_name The name of the project to be retrieved
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getProjectByName(project_name: string, options?: any) {
    return ProjectApiFp(this.configuration).getProjectByName(project_name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Retrieve all the tests, with their corresponding suite membership (if any), that exist in the given project, optionally filtered by suite name
   * @param {string} project_name The name of the project that owns the tests to be retrieved
   * @param {string} [suite_name] Suite name parameter to filter the test results by, returning only those tests that are associated to the given suite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTestMembership(project_name: string, suite_name?: string, options?: any) {
    return ProjectApiFp(this.configuration).getTestMembership(project_name, suite_name, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Retrieve a trend from the specified test_name, for all the existing runs. If a date range is specified, returns only the runs inside that range
   * @param {string} project_name The name for the project that owns this test
   * @param {string} test_name The test name from which to return the Trend for all the existing runs
   * @param {string} [from] The lower bound of the date range
   * @param {string} [to] The upper bound of the date range, can\&#39;t be older than from parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTrend(project_name: string, test_name: string, from?: string, to?: string, options?: any) {
    return ProjectApiFp(this.configuration).getTrend(project_name, test_name, from, to, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get list of projects owned by an organization
   * @param {string} org_id The uuid of the organization that owns the projects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public listProjects(org_id: string, options?: any) {
    return ProjectApiFp(this.configuration).listProjects(org_id, options)(this.axios, this.basePath);
  }
}

/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a session for a user, authenticated by JWT token.  Sets a cookie which can be used for authentication in subsequent API requests.
     * @param {AuthToken} AuthToken The JWT Auth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionCreate(AuthToken: AuthToken, options: any = {}): RequestArgs {
      // verify required parameter 'AuthToken' is not null or undefined
      if (AuthToken === null || AuthToken === undefined) {
        throw new RequiredError(
          "AuthToken",
          "Required parameter AuthToken was null or undefined when calling sessionCreate.",
        );
      }
      const localVarPath = `/session`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"AuthToken" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(AuthToken !== undefined ? AuthToken : {})
        : AuthToken || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionDestroy(options: any = {}): RequestArgs {
      const localVarPath = `/session`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a session for a user, authenticated by JWT token.  Sets a cookie which can be used for authentication in subsequent API requests.
     * @param {AuthToken} AuthToken The JWT Auth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionCreate(
      AuthToken: AuthToken,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SessionApiAxiosParamCreator(configuration).sessionCreate(AuthToken, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionDestroy(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SessionApiAxiosParamCreator(configuration).sessionDestroy(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a session for a user, authenticated by JWT token.  Sets a cookie which can be used for authentication in subsequent API requests.
     * @param {AuthToken} AuthToken The JWT Auth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionCreate(AuthToken: AuthToken, options?: any) {
      return SessionApiFp(configuration).sessionCreate(AuthToken, options)(axios, basePath);
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionDestroy(options?: any) {
      return SessionApiFp(configuration).sessionDestroy(options)(axios, basePath);
    },
  };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
  /**
   *
   * @summary Create a session for a user, authenticated by JWT token.  Sets a cookie which can be used for authentication in subsequent API requests.
   * @param {AuthToken} AuthToken The JWT Auth token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionApi
   */
  public sessionCreate(AuthToken: AuthToken, options?: any) {
    return SessionApiFp(this.configuration).sessionCreate(AuthToken, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Destroy the session.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionApi
   */
  public sessionDestroy(options?: any) {
    return SessionApiFp(this.configuration).sessionDestroy(options)(this.axios, this.basePath);
  }
}

/**
 * SuiteApi - axios parameter creator
 * @export
 */
export const SuiteApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a suite owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {Suite} Suite Benchmark suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSuite(project_name: string, Suite: Suite, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling addSuite.",
        );
      }
      // verify required parameter 'Suite' is not null or undefined
      if (Suite === null || Suite === undefined) {
        throw new RequiredError("Suite", "Required parameter Suite was null or undefined when calling addSuite.");
      }
      const localVarPath = `/project/{project_name}/suite`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Suite" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(Suite !== undefined ? Suite : {}) : Suite || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Associate the benchmark suite with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be associated to the given run
     * @param {string} test_name Th name of the test to be associated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTestToSuite(project_name: string, suite_id: string, test_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling addTestToSuite.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling addTestToSuite.",
        );
      }
      // verify required parameter 'test_name' is not null or undefined
      if (test_name === null || test_name === undefined) {
        throw new RequiredError(
          "test_name",
          "Required parameter test_name was null or undefined when calling addTestToSuite.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}/test/{test_name}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)))
        .replace(`{${"test_name"}}`, encodeURIComponent(String(test_name)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a benchmark suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSuite(project_name: string, suite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling deleteSuite.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling deleteSuite.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete benchmark suite association with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be dissociated to the given run
     * @param {string} test_name The name of the test to be dissociated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTestFromSuite(project_name: string, suite_id: string, test_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling deleteTestFromSuite.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling deleteTestFromSuite.",
        );
      }
      // verify required parameter 'test_name' is not null or undefined
      if (test_name === null || test_name === undefined) {
        throw new RequiredError(
          "test_name",
          "Required parameter test_name was null or undefined when calling deleteTestFromSuite.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}/test/{test_name}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)))
        .replace(`{${"test_name"}}`, encodeURIComponent(String(test_name)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get suite\'s regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegression(project_name: string, suite_id: string, from?: string, to?: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getRegression.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling getRegression.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}/regression`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a benchmark suite by uuid, with regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuiteById(project_name: string, suite_id: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling getSuiteById.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling getSuiteById.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a list of suites owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSuites(project_name: string, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling listSuites.",
        );
      }
      const localVarPath = `/project/{project_name}/suite`.replace(
        `{${"project_name"}}`,
        encodeURIComponent(String(project_name)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be updated
     * @param {UpdateSuite} UpdateSuite Update suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSuite(project_name: string, suite_id: string, UpdateSuite: UpdateSuite, options: any = {}): RequestArgs {
      // verify required parameter 'project_name' is not null or undefined
      if (project_name === null || project_name === undefined) {
        throw new RequiredError(
          "project_name",
          "Required parameter project_name was null or undefined when calling updateSuite.",
        );
      }
      // verify required parameter 'suite_id' is not null or undefined
      if (suite_id === null || suite_id === undefined) {
        throw new RequiredError(
          "suite_id",
          "Required parameter suite_id was null or undefined when calling updateSuite.",
        );
      }
      // verify required parameter 'UpdateSuite' is not null or undefined
      if (UpdateSuite === null || UpdateSuite === undefined) {
        throw new RequiredError(
          "UpdateSuite",
          "Required parameter UpdateSuite was null or undefined when calling updateSuite.",
        );
      }
      const localVarPath = `/project/{project_name}/suite/{suite_id}`
        .replace(`{${"project_name"}}`, encodeURIComponent(String(project_name)))
        .replace(`{${"suite_id"}}`, encodeURIComponent(String(suite_id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function" ? configuration.apiKey("X-API-KEY") : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdateSuite" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(UpdateSuite !== undefined ? UpdateSuite : {})
        : UpdateSuite || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SuiteApi - functional programming interface
 * @export
 */
export const SuiteApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a suite owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {Suite} Suite Benchmark suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSuite(
      project_name: string,
      Suite: Suite,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suite> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).addSuite(project_name, Suite, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Associate the benchmark suite with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be associated to the given run
     * @param {string} test_name Th name of the test to be associated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTestToSuite(
      project_name: string,
      suite_id: string,
      test_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).addTestToSuite(
        project_name,
        suite_id,
        test_name,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a benchmark suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSuite(
      project_name: string,
      suite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).deleteSuite(project_name, suite_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete benchmark suite association with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be dissociated to the given run
     * @param {string} test_name The name of the test to be dissociated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTestFromSuite(
      project_name: string,
      suite_id: string,
      test_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).deleteTestFromSuite(
        project_name,
        suite_id,
        test_name,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get suite\'s regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegression(
      project_name: string,
      suite_id: string,
      from?: string,
      to?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Regression> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).getRegression(
        project_name,
        suite_id,
        from,
        to,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a benchmark suite by uuid, with regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuiteById(
      project_name: string,
      suite_id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suite> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).getSuiteById(project_name, suite_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a list of suites owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSuites(
      project_name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Suite>> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).listSuites(project_name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be updated
     * @param {UpdateSuite} UpdateSuite Update suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSuite(
      project_name: string,
      suite_id: string,
      UpdateSuite: UpdateSuite,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = SuiteApiAxiosParamCreator(configuration).updateSuite(
        project_name,
        suite_id,
        UpdateSuite,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SuiteApi - factory interface
 * @export
 */
export const SuiteApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a suite owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {Suite} Suite Benchmark suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSuite(project_name: string, Suite: Suite, options?: any) {
      return SuiteApiFp(configuration).addSuite(project_name, Suite, options)(axios, basePath);
    },
    /**
     *
     * @summary Associate the benchmark suite with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be associated to the given run
     * @param {string} test_name Th name of the test to be associated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTestToSuite(project_name: string, suite_id: string, test_name: string, options?: any) {
      return SuiteApiFp(configuration).addTestToSuite(project_name, suite_id, test_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a benchmark suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSuite(project_name: string, suite_id: string, options?: any) {
      return SuiteApiFp(configuration).deleteSuite(project_name, suite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete benchmark suite association with the given test
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite which will be dissociated to the given run
     * @param {string} test_name The name of the test to be dissociated with the suite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTestFromSuite(project_name: string, suite_id: string, test_name: string, options?: any) {
      return SuiteApiFp(configuration).deleteTestFromSuite(project_name, suite_id, test_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Get suite\'s regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {string} [from] The lower bound of the date range
     * @param {string} [to] The upper bound of the date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegression(project_name: string, suite_id: string, from?: string, to?: string, options?: any) {
      return SuiteApiFp(configuration).getRegression(project_name, suite_id, from, to, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a benchmark suite by uuid, with regression from latest runs or in a provided date range
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid of the benchmark suite to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuiteById(project_name: string, suite_id: string, options?: any) {
      return SuiteApiFp(configuration).getSuiteById(project_name, suite_id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a list of suites owned by a project
     * @param {string} project_name The name of the project that owns the suites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSuites(project_name: string, options?: any) {
      return SuiteApiFp(configuration).listSuites(project_name, options)(axios, basePath);
    },
    /**
     *
     * @summary Update suite
     * @param {string} project_name The name for the project that owns this suite
     * @param {string} suite_id The uuid for the suite to be updated
     * @param {UpdateSuite} UpdateSuite Update suite payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSuite(project_name: string, suite_id: string, UpdateSuite: UpdateSuite, options?: any) {
      return SuiteApiFp(configuration).updateSuite(project_name, suite_id, UpdateSuite, options)(axios, basePath);
    },
  };
};

/**
 * SuiteApi - object-oriented interface
 * @export
 * @class SuiteApi
 * @extends {BaseAPI}
 */
export class SuiteApi extends BaseAPI {
  /**
   *
   * @summary Create a suite owned by a project
   * @param {string} project_name The name of the project that owns the suites
   * @param {Suite} Suite Benchmark suite payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public addSuite(project_name: string, Suite: Suite, options?: any) {
    return SuiteApiFp(this.configuration).addSuite(project_name, Suite, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Associate the benchmark suite with the given test
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid of the benchmark suite which will be associated to the given run
   * @param {string} test_name Th name of the test to be associated with the suite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public addTestToSuite(project_name: string, suite_id: string, test_name: string, options?: any) {
    return SuiteApiFp(this.configuration).addTestToSuite(project_name, suite_id, test_name, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Delete a benchmark suite
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid for the suite to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public deleteSuite(project_name: string, suite_id: string, options?: any) {
    return SuiteApiFp(this.configuration).deleteSuite(project_name, suite_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete benchmark suite association with the given test
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid of the benchmark suite which will be dissociated to the given run
   * @param {string} test_name The name of the test to be dissociated with the suite
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public deleteTestFromSuite(project_name: string, suite_id: string, test_name: string, options?: any) {
    return SuiteApiFp(this.configuration).deleteTestFromSuite(project_name, suite_id, test_name, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get suite\'s regression from latest runs or in a provided date range
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid of the benchmark suite to be retrieved
   * @param {string} [from] The lower bound of the date range
   * @param {string} [to] The upper bound of the date range
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public getRegression(project_name: string, suite_id: string, from?: string, to?: string, options?: any) {
    return SuiteApiFp(this.configuration).getRegression(project_name, suite_id, from, to, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get a benchmark suite by uuid, with regression from latest runs or in a provided date range
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid of the benchmark suite to be retrieved
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public getSuiteById(project_name: string, suite_id: string, options?: any) {
    return SuiteApiFp(this.configuration).getSuiteById(project_name, suite_id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a list of suites owned by a project
   * @param {string} project_name The name of the project that owns the suites
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public listSuites(project_name: string, options?: any) {
    return SuiteApiFp(this.configuration).listSuites(project_name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update suite
   * @param {string} project_name The name for the project that owns this suite
   * @param {string} suite_id The uuid for the suite to be updated
   * @param {UpdateSuite} UpdateSuite Update suite payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuiteApi
   */
  public updateSuite(project_name: string, suite_id: string, UpdateSuite: UpdateSuite, options?: any) {
    return SuiteApiFp(this.configuration).updateSuite(project_name, suite_id, UpdateSuite, options)(
      this.axios,
      this.basePath,
    );
  }
}
