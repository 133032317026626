import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { styles } from "../Styles/layout";
import Tolerance from "./Tolerance";

interface IProps extends WithStyles<typeof styles> {}

function AccountSettings(props: IProps) {
  return (
    <React.Fragment>
      <Typography data-testid="settings_section_title" variant="h5">
        Account Settings
      </Typography>
      <Tolerance />
    </React.Fragment>
  );
}

export default withStyles(styles)(AccountSettings);
