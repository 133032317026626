import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
/* components */
import ApiKeyListItem from "./ApiKeyListItem";

import { observer } from "mobx-react-lite";
import { ExistingKey } from "../../../generatedApi";

interface IApiKeyListProps {
  apiKeys: ExistingKey[];
  accountId: string;
}

const ApiKeyList = observer((props: IApiKeyListProps) => {
  if (!props.apiKeys) {
    return null;
  }

  return (
    <TableBody data-testid="api_keys_table">
      {props.apiKeys.map((apiKey: ExistingKey, index: number) => {
        return (
          <TableRow key={index}>
            <ApiKeyListItem apiKey={apiKey} accountId={props.accountId} key={index} />
          </TableRow>
        );
      })}
    </TableBody>
  );
});

export default ApiKeyList;
