import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { TestSuiteMembership } from "../../../generatedApi";
import { IAppProps } from "../../../services/helpers/AppProps";
import { Scope } from "../../../services/helpers/scopes";
import AppContext from "../../../stores/AppStore";
import Conditional from "../../Conditional";
import { styles } from "../../Styles/layout";
import { AddTestToSuiteContext } from "./AddTestToSuite";

function SuiteManagementMenu() {
  const { setOpenAddDialog } = useContext(AddTestToSuiteContext) as {
    setOpenAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const appStore = useContext(AppContext);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAdd() {
    setOpenAddDialog(true);
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={open ? "management-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        data-testid="suite_management_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={handleAdd} disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}>
          Add to a Suite
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={!appStore.scopes.includes(Scope.PROJECT_UPDATE)}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

interface IManageSuiteItemProps {
  membership: TestSuiteMembership;
  classes: IAppProps["classes"];
}

const ManageSuiteListItem = observer((props: IManageSuiteItemProps) => {
  const { membership, classes } = props;

  return (
    <React.Fragment>
      <TableCell align="left" className={classes.borderRight} component="th" scope="row">
        {membership.test_name}
      </TableCell>
      <TableCell align="left">
        {membership.suite_names.map((suiteName: string, index: number) => {
          return <Chip key={index} label={suiteName} />;
        })}
        <Conditional if={membership.suite_names.length <= 0}>
          <i>uncategorized</i>
        </Conditional>
      </TableCell>
      <TableCell align="right">
        <SuiteManagementMenu />
      </TableCell>
    </React.Fragment>
  );
});
export default withStyles(styles)(ManageSuiteListItem);
