import { IconButton, Link, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ErrorBody, ExistingKey } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import ApiKeysContext from "../../../stores/ApiKeysStore";
import NotificationContext from "../../../stores/NotificationStore";
import { styles } from "../../Styles/layout";
import DeleteDialog from "../../UI/DeleteDialog";
import { useDialog } from "../../UI/SimpleDialog";

interface IApiKeyItemProps extends WithStyles<typeof styles> {
  apiKey: ExistingKey;
  accountId: string;
}

const ApiKeyListItem = (props: IApiKeyItemProps) => {
  const dudUrl = "javascript:;";
  let timer: any = null;

  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const [label, setLabel] = React.useState("Copy");
  const errorHandler = useErrorHandler();

  const apiKeysStore = useContext(ApiKeysContext);
  const notificationStore = useContext(NotificationContext);
  const { apiKey, classes } = props;

  function handleCopy() {
    setLabel("Copied");
    timer = setInterval(() => {
      setLabel("Copy");
      clearInterval(timer);
    }, 400);
  }

  async function handleDelete() {
    toggleDeleteDialog();
    try {
      await apiKeysStore.deleteApiKey(apiKey.uuid);
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          Successfully deleted API Key <strong>{apiKey.name}</strong>.
        </Typography>,
        "success",
      );
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(
        errorHandler(error, {
          403: (data: ErrorBody) => {
            return (
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{apiKey.name}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  return (
    <React.Fragment>
      <TableCell align="left" className={classes.borderRight}>
        {apiKey.name}
      </TableCell>
      <TableCell align="left" style={{ display: "flex" }}>
        <div style={{ alignSelf: "center" }}>
          {apiKey.value}&nbsp;
          <CopyToClipboard text={apiKey.value} onCopy={handleCopy}>
            <Link color="secondary" href={dudUrl} underline="always" style={{ position: "absolute" }}>
              {label}
            </Link>
          </CopyToClipboard>
        </div>
        <IconButton
          data-testid="api_key_delete_option"
          aria-label="Delete API Key"
          style={{ color: "black", marginLeft: "auto" }}
          onClick={toggleDeleteDialog}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <DeleteDialog
        active={isDeleteDialogOpen}
        toggleActive={toggleDeleteDialog}
        name={apiKey.name}
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(ApiKeyListItem);
