import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingOrganization, NewOrganization, UpdateOrganization } from "../generatedApi";
import { organizationApi } from "../services/api";

export class OrganizationsStore {
  @computed get organizations() {
    return Array.from(this.organizationRegistry.values());
  }
  set organizations(organizations: ExistingOrganization[]) {
    this.organizationRegistry.clear();
    organizations.forEach(org => {
      this.organizationRegistry.set(org.uuid, org);
    });
  }

  public static async loadScopes(organizationId: string) {
    try {
      const response = await organizationApi.listScopes(organizationId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @observable private organizationRegistry = observable.map<string, ExistingOrganization>();

  public getOrganization(organizationId: string) {
    const org = this.organizationRegistry.get(organizationId);
    if (org) {
      return org;
    }
    return { uuid: "", name: "", email: "", personal: false };
  }

  @action public async loadOrganizations() {
    try {
      const response = await organizationApi.listOrganizations();
      this.organizations = response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async loadOrganization(organizationId: string) {
    try {
      const response = await organizationApi.getOrganizationById(organizationId);
      this.organizationRegistry.set(response.data.uuid, response.data);
    } catch (error) {
      throw error;
    }
  }

  @action public async createOrganization(organization: NewOrganization) {
    try {
      const response = await organizationApi.addOrganization(organization);
      this.organizationRegistry.set(response.data.uuid, response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async deleteOrganization(organizationId: string) {
    try {
      await organizationApi.deleteOrganization(organizationId);
      this.organizationRegistry.delete(organizationId);
    } catch (error) {
      throw error;
    }
  }

  @action public async updateOrganization(organizationId: string, updateOrg: UpdateOrganization) {
    try {
      await organizationApi.updateOrganization(organizationId, updateOrg);
      await this.loadOrganization(organizationId);
    } catch (error) {
      throw error;
    }
  }
}

const organizationsStore = new OrganizationsStore();
export default createContext(organizationsStore);
