import blueGrey from "@material-ui/core/colors/blueGrey";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import firebase from "firebase";
import { SnackbarProvider } from "notistack";
import React, { createContext } from "react";
import { IsDevo, IsLocal } from "../config";
import Routes from "./Routes";
import "./Styles/firebase-ui.css";

const devoConfig = {
  apiKey: "AIzaSyBkAZLxB8yZ71bN54bJOqVuBSQ1a7gAS9Y",
  authDomain: "performance-benchmarks-devo.firebaseapp.com",
  databaseURL: "https://performance-benchmarks-devo.firebaseio.com",
  projectId: "performance-benchmarks-devo",
  storageBucket: "performance-benchmarks-devo.appspot.com",
  messagingSenderId: "168620824736",
};

const stagingConfig = {
  apiKey: "AIzaSyCp15eHwaMI__Ut3hkLDx1Ya0kJSuQFXSk",
  authDomain: "performance-benchmarks-staging.firebaseapp.com",
  databaseURL: "https://performance-benchmarks-staging.firebaseio.com",
  projectId: "performance-benchmarks-staging",
  storageBucket: "performance-benchmarks-staging.appspot.com",
  messagingSenderId: "168620824736",
};

if (IsDevo) {
  firebase.initializeApp(devoConfig);
} else {
  firebase.initializeApp(stagingConfig);
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey["900"],
      light: blueGrey["700"],
      contrastText: "#fff",
    },
    secondary: {
      main: blueGrey["800"],
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "$labelColor",
          borderRadius: "4px",
        },
      },
    },
  },
});

function App() {
  return (
    <SnackbarProvider
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </SnackbarProvider>
  );
}

export const firebaseAuthContext = createContext(firebase.auth());
export default App;
