import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Duration, Measurement, Regression as IRegression, Result as IResult, Test as ITest } from "../generatedApi";
import { suiteApi } from "../services/api";

class Test implements ITest {
  public name: string;
  public measurements: { [key: string]: Measurement };

  constructor(test?: ITest) {
    if (test) {
      this.name = test.name;
      this.measurements = test.measurements;
    } else {
      this.name = "";
      this.measurements = {};
    }
  }

  get measurementsValues(): number[] {
    return Object.keys(this.measurements)
      .sort()
      .map((metricName: string) => {
        return this.measurements[metricName].value;
      });
  }
}

export class Result implements IResult {
  public name: string;
  public metricNames: Array<{ name: string; units: string }> = [];
  public latest: Test = new Test();
  public average: Test = new Test();
  public delta: Test = new Test();

  constructor(testName: string, result: IResult) {
    this.name = testName;
    if (result.latest) {
      this.latest = new Test(result.latest);
      this.setMetricNames(this.latest);
    }
    if (result.average) {
      this.average = new Test(result.average);
      this.setMetricNames(this.average);
    }
    if (result.delta) {
      this.delta = new Test(result.delta);
      this.setMetricNames(this.delta);
    }
  }

  public setMetricNames(test: Test) {
    if (this.metricNames.length < Object.keys(test.measurements).length) {
      this.metricNames = Object.keys(test.measurements)
        .sort()
        .map((metricName: string) => {
          return { name: metricName, units: test.measurements[metricName].metric.units };
        });
    }
  }
}

export class Regression implements IRegression {
  public duration: Duration;
  public results: { [key: string]: Result };

  constructor(regression?: IRegression) {
    this.results = {};
    if (regression) {
      this.duration = regression.duration;
      Object.keys(regression.results).forEach((testName: string) => {
        this.results[testName] = new Result(testName, regression.results[testName]);
      });
    } else {
      this.duration = { start: "", end: "" };
    }
  }

  public resultsValues(orderDir: "asc" | "desc"): Result[] {
    let orderFunc;
    if (orderDir === "desc") {
      orderFunc = (x: string, y: string) => (x > y ? -1 : 1);
    }
    return Object.keys(this.results)
      .sort(orderFunc)
      .map((testName: string) => {
        return this.results[testName];
      });
  }
}

export class RegressionStore {
  // tslint:disable-next-line: variable-name
  @observable private _regression: Regression = new Regression();
  @observable private suiteId: string = "";
  @observable private projectName: string = "";

  @computed public get regression() {
    return this._regression;
  }
  public set regression(regression: Regression) {
    this._regression = regression;
  }

  @action public setSuite(suiteId: string) {
    if (this.suiteId !== suiteId) {
      this._regression = new Regression();
      this.suiteId = suiteId;
    }
  }

  @action public setProject(projectName: string) {
    if (this.projectName !== projectName) {
      this._regression = new Regression();
      this.projectName = projectName;
    }
  }

  @action
  public async loadRegression(from?: string, to?: string) {
    try {
      const response = await suiteApi.getRegression(this.projectName, this.suiteId, from, to);
      this._regression = new Regression(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        this._regression = new Regression();
      }
      throw error;
    }
  }
}

const regressionStore = new RegressionStore();
export default createContext(regressionStore);
