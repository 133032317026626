import { FormControl, InputLabel, Select, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useContext, useState } from "react";
import { ErrorBody, ExistingOrganization } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import InvitesContext from "../../../stores/InvitesStore";
import NotificationContext from "../../../stores/NotificationStore";
import { styles } from "../../Styles/layout";
import SimpleDialog from "../../UI/SimpleDialog";

interface IInviteUserProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const InviteUser = (props: IInviteUserProps) => {
  const defaultRole: string = "editor";

  const [userEmail, setUserEmail] = useState({ value: "", error: "" });
  const [userRole, setUserRole] = useState({ value: defaultRole, error: "" });
  const [loading, setLoading] = useState(false);
  const errorHandler = useErrorHandler();

  const invitesStore = useContext(InvitesContext);
  const notificationStore = useContext(NotificationContext);

  function closeDialog() {
    props.dialogToggle();
    setUserEmail({ value: "", error: "" });
    setUserRole({ value: defaultRole, error: "" });
  }

  function handleInput(input: string, field: "userEmail" | "userRole") {
    if (field === "userEmail") {
      setUserEmail({ value: input, error: input ? "" : userEmail.error });
    } else {
      setUserRole({ value: input, error: input ? "" : userRole.error });
    }
  }

  async function handleSubmit() {
    setLoading(true);
    const userEmailVal = userEmail.value;
    const userRoleVal = userRole.value;

    if (userEmailVal) {
      if (!userEmailVal.match(/.+@.+\..+/i)) {
        setUserEmail({ ...userEmail, error: "This field must be a valid email" });
        setLoading(false);
        return;
      }

      try {
        await invitesStore.createInvite({
          email: userEmailVal,
          role: userRoleVal,
        });
        closeDialog();
      } catch (error) {
        setUserEmail({
          ...userEmail,
          error: errorHandler(error, {
            303: () => "An invitation to that email already exists",
            403: (data: ErrorBody) => {
              notificationStore.enqueueAutohideSnackbar(
                <Typography color="inherit">
                  {mapErrorBody(data)}: <strong>{props.org.name}</strong>
                </Typography>,
                "error",
              );
              closeDialog();
              return "";
            },
          }) as string,
        });
      }
    } else {
      setUserEmail({ ...userEmail, error: "The email is required!" });
    }

    setLoading(false);
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Invite new user"}
      actionLabel="SEND INVITE"
      onAction={handleSubmit}
      actionButtonProps={{ "data-testid": "send_invite_button", disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
    >
      <form className={props.classes.form}>
        <FormControl className={props.classes.form}>
          <TextField
            label="Email Address"
            name="user_email"
            multiline={true}
            helperText={userEmail.error}
            error={userEmail.error !== ""}
            fullWidth={true}
            value={userEmail.value}
            onChange={evt => handleInput(evt.target.value as string, "userEmail")}
            data-testid="invite_user_email"
            disabled={loading}
          />
        </FormControl>
        <FormControl className={props.classes.form} />
        <FormControl className={props.classes.form}>
          <InputLabel shrink={true}>Role</InputLabel>
          <Select
            value={userRole.value}
            onChange={evt => handleInput(evt.target.value as string, "userRole")}
            disabled={loading}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="editor">Editor</MenuItem>
            <MenuItem value="viewer">Viewer</MenuItem>
          </Select>
        </FormControl>
      </form>
    </SimpleDialog>
  );
};

export default withStyles(styles)(InviteUser);
