export enum Scope {
  ORG_READ = "org:read",
  ORG_UPDATE = "org:update",
  ORG_DELETE = "org:delete",
  ORG_ACCOUNT_READ = "org_account:read",
  ORG_ACCOUNT_UPDATE = "org_account:update",
  ORG_ACCOUNT_DELETE = "org_account:delete",
  ORG_INVITE_CREATE = "org_invite:create",
  ORG_INVITE_READ = "org_invite:read",
  ORG_INVITE_UPDATE = "org_invite:update",
  ORG_INVITE_DELETE = "org_invite:delete",
  PROJECT_CREATE = "project:create",
  PROJECT_READ = "project:read",
  PROJECT_UPDATE = "project:update",
  PROJECT_DELETE = "project:delete",
}
