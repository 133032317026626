import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase";
import { History } from "history";
import React, { useContext } from "react";
import SnackbarContext from "../../stores/SnackBar";
import { SIGN_IN_PATH } from "../Routes";
import Loader, { ProgressStyles } from "../UI/Loader";
import Snackbar from "../UI/SnackBar";

const styles = (theme: any) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(6),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    layout: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(500 + theme.spacing(3) * 2)]: {
        width: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    section: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
}

function ForgotPassword(props: IProps) {
  const snackbarStore = useContext(SnackbarContext);
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get("email") as string;
    if (email) {
      const res = await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          snackbarStore.setData({
            message:
              "Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.",
            variant: "success",
          });
          history.push(SIGN_IN_PATH);
        })
        .catch(error => {
          switch (error.code) {
            case "auth/invalid-email":
              snackbarStore.setData({ message: "The email address is not valid.", variant: "error" });
              setLoading(false);
              break;
            case "auth/user-not-found":
              snackbarStore.setData({
                message: "There is no user corresponding to the email address.",
                variant: "error",
              });
              setLoading(false);
              break;
            default:
              // tslint:disable-next-line: no-console
              console.error("Unrecognized error code: " + error.code);
          }
        });
    } else {
      setLoading(false);
      snackbarStore.setData({ message: "The email address is not valid.", variant: "error" });
    }
  };

  return (
    <main className={classes.layout}>
      <Snackbar />
      <Paper className={classes.paper}>
        <Typography variant="h5" align="left" paragraph={true}>
          Reset your password
        </Typography>
        <Typography variant="body2">
          Enter your email address and we will send you a link to reset your password.
        </Typography>

        <Loader loaded={!loading} styles={ProgressStyles}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl margin="normal" fullWidth={true}>
              <TextField
                name="email"
                id="outlined-name"
                label="Email Address"
                margin="normal"
                variant="outlined"
                disabled={loading}
              />
            </FormControl>
            <FormControl fullWidth={true} className={classes.section}>
              <Button type="submit" fullWidth={true} variant="contained" color="primary" disabled={loading}>
                Send password reset email
              </Button>
            </FormControl>
          </form>
        </Loader>
      </Paper>
    </main>
  );
}

export default withStyles(styles)(ForgotPassword);
