import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { DataPoint } from "../generatedApi";
import { projectApi } from "../services/api";

const DOMAIN_MARGIN_PERCENTAGE = 0.05;

export class TrendStore {
  @observable public loading: boolean = true;
  @observable public dataPoints: DataPoint[] = [];
  @observable public testName: string = "";
  @observable private projectName: string = "";

  @computed public get metrics() {
    const metrics: string[] = [];
    this.dataPoints.forEach((point: DataPoint) => {
      Object.keys(point.measurements).forEach((metricName: string) => {
        if (metrics.indexOf(metricName) === -1) {
          metrics.push(metricName);
        }
      });
    });
    return metrics;
  }

  @computed public get chartsData() {
    const data: object[] = [];
    let dataset: any = {};

    this.dataPoints.forEach((point: DataPoint) => {
      dataset = {};
      dataset.name = point.time;
      Object.keys(point.measurements).forEach((metricName: string) => {
        const measurement = point.measurements[metricName];

        dataset[metricName] = measurement.value;
        dataset[`${metricName}-error`] = [
          measurement.value - measurement.error.min,
          measurement.error.max - measurement.value,
        ];
      });
      data.push(dataset);
    });

    return data;
  }

  public domain(disabled: string[] = []): [number, number] {
    const dataMin = Math.min(
      ...([] as number[]).concat(
        ...this.dataPoints.map(dp =>
          Object.keys(dp.measurements)
            .filter(metricName => !disabled.includes(metricName))
            .map(metricName => {
              return dp.measurements[metricName].error.min;
            }),
        ),
      ),
    );

    const dataMax = Math.max(
      ...([] as number[]).concat(
        ...this.dataPoints.map(dp =>
          Object.keys(dp.measurements)
            .filter(metricName => !disabled.includes(metricName))
            .map(metricName => {
              return dp.measurements[metricName].error.max;
            }),
        ),
      ),
    );

    const margin = (dataMax - dataMin) * DOMAIN_MARGIN_PERCENTAGE;

    return [Math.floor(dataMin - margin), Math.ceil(dataMax + margin)];
  }

  @action public setProject(projectName: string) {
    if (this.projectName !== projectName) {
      this.dataPoints = [];
      this.projectName = projectName;
    }
  }

  @action public async loadTrend(testName: string, from?: string, to?: string) {
    try {
      const response = await projectApi.getTrend(this.projectName, testName, from, to);
      this.testName = response.data.test_name;
      this.dataPoints = response.data.data_points;
    } catch (error) {
      throw error;
    }
  }
}

const trendStore = new TrendStore();
export default createContext(trendStore);
