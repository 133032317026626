import { CircularProgress, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { formatRoute } from "react-router-named-routes";

import { useErrorHandler } from "../../services/helpers/ErrorHandler";
import AccountContext from "../../stores/AccountStore";
import AppContext from "../../stores/AppStore";
import ProjectsContext from "../../stores/ProjectsStore";
import { PROJECT_SUITES_PATH } from "../Routes";

const styles = (theme: any) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(6),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    layout: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(500 + theme.spacing(3) * 2)]: {
        width: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    section: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
    progress: {
      margin: "0 auto",
    },
  });

const ErrorBanner = observer((props: any) => {
  return <Typography variant="body1">Error! {props.children}</Typography>;
});

// tslint:disable-next-line: prettier
interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const FirstProject = observer((props: IProps) => {
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const appStore = useContext(AppContext);
  const projects = useContext(ProjectsContext);
  const errorHandler = useErrorHandler();
  const accountStore = useContext(AccountContext);

  useEffect(() => {
    setLoading(true);
    const loadAccount = async () => {
      try {
        await accountStore.loadAccount();
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };
    loadAccount();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    if (name) {
      const orgUuid = appStore.organization.uuid;
      try {
        projects.setOrganization(orgUuid);
        const project = await projects.createProject({ display: name });
        setLoading(false);
        history.push(formatRoute(PROJECT_SUITES_PATH, { projectName: project.name! }));
      } catch (error) {
        setError(errorHandler(error, {
          303: () => "See Other",
        }) as string);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError("Error");
    }
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant="h5" align="left" paragraph={true}>
          Nice! We're almost there...
        </Typography>
        <Typography variant="body2">
          Let's create your first <strong>Project</strong>. <i>You may also change this name later</i>.
        </Typography>

        {loading && <CircularProgress className={classes.progress} />}
        {error && <ErrorBanner>{error}</ErrorBanner>}

        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl margin="normal" fullWidth={true}>
            <TextField name="name" id="outlined-name" label="Project Name" variant="outlined" disabled={loading} />
          </FormControl>
          <FormControl fullWidth={true} className={classes.section}>
            <Button
              data-testid="onboarding_create_project_button"
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Create Project
            </Button>
          </FormControl>
        </form>
      </Paper>
    </main>
  );
});

export default withStyles(styles)(FirstProject);
