import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { useContext, useEffect } from "react";

import NotificationContext from "../../stores/NotificationStore";

const Notifier = observer((props: WithSnackbarProps) => {
  let displayed: number[] = [];
  const notificationStore = useContext(NotificationContext);

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id];
  };

  useEffect(() => {
    return autorun(() => {
      const { notifications = [] } = notificationStore;
      const currentKeys: number[] = [];

      notifications.forEach(notification => {
        // Do nothing if snackbar is already displayed
        if (displayed.includes(notification.key)) {
          return;
        }
        // Display snackbar using notistack
        props.enqueueSnackbar(notification.message, notification.options);
        currentKeys.push(notification.key);
        // Keep track of snackbars that we've displayed
        storeDisplayed(notification.key);
      });

      // Remove dismissed notifications
      displayed = displayed.filter(key => {
        if (!currentKeys.includes(key)) {
          props.closeSnackbar(key);
          return false;
        } else {
          return true;
        }
      });
    });
  }, []);

  return null;
});

export default withSnackbar(Notifier);
