import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import AppContext from "../../stores/AppStore";
import { drawerWidth } from "../SideBar/styles";

const styles = (theme: any) =>
  createStyles({
    footer: {
      marginTop: theme.spacing(8),
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: `${theme.spacing(6)}px 0`,
    },
    footerCenter: {
      paddingLeft: drawerWidth,
    },
  });

const SiteFooter = observer((props: any) => {
  const { classes } = props;
  const appStore = useContext(AppContext);

  return (
    <React.Fragment>
      <footer className={classNames(classes.footer)}>
        <Grid container={true} justify="center">
          <Grid item={true} className={"dashboard" === appStore.layout ? classNames(classes.footerCenter) : ""}>
            <Typography className={classes.footerLinks} color="textSecondary" variant="body2">
              A&nbsp;
              <Link color="secondary" href="https://joylabs.com" target="_blank">
                Joy&nbsp;Labs
              </Link>
              &nbsp;project
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </React.Fragment>
  );
});

export default withStyles(styles)(SiteFooter);
