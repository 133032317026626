import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    layout: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(500 /*+ theme.spacing(3) * 2 */)]: {
        width: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    formControl: {
      float: "right",
      flexDirection: "row",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: "white",
    },
    borderRight: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    floatRight: {
      float: "right",
    },
    tabs: {},
    slider: {
      width: "40%",
      alignSelf: "center",
    },

    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(6),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
  });
