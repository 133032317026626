// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Nestor
 *  ## Public OpenAPI spec for the Nestor project.  ### Permissions  Permissions will depend on your `Role` with the `Organization` as follows   (see [add_invite](#/Organization/add_invite) and [update_organization_account](#/Organization/update_organization_account) for details):  `Viewer`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read-only__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read-only__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  `Editor`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read/write__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read-only__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  `Admin`:   * __Read/write__ access to all `Account` resources ([Account](#/Account)) in your own Account   * __Read/write__ access to all `Project` resources ([Project](#/Project), [Bench](#/Bench), [Suite](#/Suite)) owned by Organization in which you have membership   * __Read/write__ access to all `Organization` resources ([Organization](#/Organization)) of Organization in which you have membership  ### Source Code  __This API is under active development, and the source repo is not public, yet.__
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: engineering@joylabs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";

export const BASE_PATH = "https://api.bench.joylabs.com/api/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError" = "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
