import { action, observable } from "mobx";
import { createContext } from "react";
import { sessionApi } from "../services/api";
import history from "../stores/History";
import { OrganizationsStore } from "./OrganizationsStore";

export class AppStore {
  @observable public organization: { name: string; uuid: string };
  @observable public scopes: string[] = [];
  @observable public layout: string = "default";
  private window: WindowLocalStorage;

  constructor(window: WindowLocalStorage) {
    this.window = window;
    const orgName = window.localStorage.getItem("org_name");
    const orgUuid = window.localStorage.getItem("org_uuid");
    this.organization = orgName && orgUuid ? { name: orgName, uuid: orgUuid } : { name: "", uuid: "" };
    if (orgUuid) {
      OrganizationsStore.loadScopes(orgUuid).then(scopes => {
        this.scopes = scopes;
      });
    }
  }

  @action public async setOrganization(name: string, uuid: string) {
    this.window.localStorage.setItem("org_name", name);
    this.window.localStorage.setItem("org_uuid", uuid);
    this.organization = { name, uuid };
    this.scopes = [];
    if (uuid !== "") {
      this.scopes = await OrganizationsStore.loadScopes(uuid);
    }
  }

  public async logIn(token: string) {
    try {
      const res = await sessionApi.sessionCreate({ token }, { headers: { "x-goog-downcase-all-headers": "x" } });

      if (!(res && 204 === res.status)) {
        throw new Error("Log in failed");
      }
    } catch (err) {
      throw err;
    }
  }

  @action public async logOut() {
    await sessionApi.sessionDestroy();
    this.clear();
    history.push("/");
  }

  public isLoggedIn() {
    return Boolean(this.organization.uuid);
  }

  public clear() {
    this.window.localStorage.clear();
    this.setOrganization("", "");
    this.layout = "default";
  }

  @action public setLayout(layout: string) {
    this.layout = layout;
  }
}

const appStore = new AppStore(window);
export default createContext(appStore);
