import { IconButton, Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useContext } from "react";
import { ExistingOrgInvite } from "../../../generatedApi";
import { useErrorHandler } from "../../../services/helpers/ErrorHandler";
import AccountContext from "../../../stores/AccountStore";
import NotificationContext from "../../../stores/NotificationStore";
import OrgContext from "../../../stores/OrganizationsStore";
import { styles } from "../../Styles/layout";

interface IMenuProps {
  accountId: string;
  inviteId: string;
  orgName: string;
}

const InviteMenu = (props: IMenuProps) => {
  const errorHandler = useErrorHandler();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const notificationStore = useContext(NotificationContext);
  const orgStore = useContext(OrgContext);
  const accountStore = useContext(AccountContext);
  const { inviteId, orgName } = props;

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function joinOrg() {
    try {
      await accountStore.acceptInvite(inviteId);
      orgStore.loadOrganizations();
      notificationStore.enqueueAutohideSnackbar("Succesfully joined organization", "success");
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(errorHandler(error), "error");
    }
  }

  async function declineInvite() {
    try {
      await accountStore.rejectInvite(inviteId);
      orgStore.loadOrganizations();
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          Succesfully declined invite to organization <strong>{orgName}</strong>
        </Typography>,
        "success",
      );
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(errorHandler(error), "error");
    }
  }

  return (
    <React.Fragment>
      <IconButton aria-label="More" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={joinOrg}>Join</MenuItem>
        <MenuItem onClick={declineInvite}>Decline</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

interface IListItemProps extends WithStyles<typeof styles> {
  invite: ExistingOrgInvite;
  accountId: string;
}

const ReceivedInviteListItem = (props: IListItemProps) => {
  return (
    <React.Fragment>
      <TableCell align="left" className={props.classes.borderRight}>
        {props.invite.name}
        <span style={{ color: "#ff8d14" }}>
          <i>Pending</i>
        </span>
      </TableCell>
      <TableCell align="left">---</TableCell>
      <TableCell align="right">
        <InviteMenu inviteId={props.invite.uuid} accountId={props.accountId} orgName={props.invite.name} />
      </TableCell>
    </React.Fragment>
  );
};

export default withStyles(styles)(ReceivedInviteListItem);
