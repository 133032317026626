import { blue, cyan, green, indigo, lightBlue, lime, purple, teal } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import * as qs from "query-string";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { match } from "react-router";
import {
  AxisDomain,
  CartesianGrid,
  ErrorBar,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IAppProps } from "../../services/helpers/AppProps";
import { useErrorHandler } from "../../services/helpers/ErrorHandler";
import { getDateRange, setDateRange } from "../../services/helpers/setDateRange";
import TrendContext from "../../stores/TrendStore";
import { styles as layoutStyles } from "../Styles/layout";
import ToggleLegend from "./ToggleLegend";

const styles = (theme: any) =>
  createStyles({
    ...layoutStyles(theme),
    lineChart: { fontFamily: "sans-serif", fontSize: "14px" },
  });

interface ITrendGraphProps extends WithStyles<typeof styles> {}

const TrendGraph = observer((props: ITrendGraphProps) => {
  const disabledSeriesDefault: string[] = [];
  const [disabledSeries, setDisabledSeries] = useState(disabledSeriesDefault);
  const trendStore = useContext(TrendContext);
  const [yDomain, setYDomain] = useState<[AxisDomain, AxisDomain]>(trendStore.domain(disabledSeries));
  const { classes } = props;

  useEffect(() => {
    setYDomain(trendStore.domain(disabledSeries));
  }, [disabledSeries, trendStore.dataPoints]);

  const seriesEnabled = (series: string): boolean => {
    const enabled = disabledSeries.every(obj => obj !== series);
    return enabled;
  };

  const toggleSeriesEnabled = (series: string) => {
    let disabled = disabledSeries;
    disabled = seriesEnabled(series) ? disabled.concat(series) : disabled.filter(obj => obj !== series);
    setDisabledSeries(disabled);
  };

  const mangle = (metric: string) => {
    return metric + "-disabled";
  };

  const unmangle = (metric: string) => {
    return metric.split("-")[0];
  };

  return (
    <ResponsiveContainer width="99%" height={320}>
      <LineChart className={classes.lineChart} data={trendStore.chartsData}>
        <XAxis dataKey="name" />
        <YAxis domain={yDomain} allowDecimals={false} allowDataOverflow={true} />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend
          wrapperStyle={{ bottom: "-15px" }}
          content={
            <ToggleLegend
              enabled={seriesEnabled}
              toggleEnabled={toggleSeriesEnabled}
              formatDataKey={(value: any) => unmangle(value as string)}
            />
          }
        />
        {trendStore.metrics.map((metric: string, index: number) => {
          return (
            <Line
              key={index}
              type="monotone"
              dataKey={seriesEnabled(metric) ? metric : mangle(metric)}
              stroke={SeriesColor(index)}
            >
              <ErrorBar dataKey={seriesEnabled(metric) ? metric + "-error" : mangle(metric) + "-error"} />
            </Line>
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
});

export const StyledTrendGraph = withStyles(styles)(TrendGraph);

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string; suiteId: string; test: string }>;
  location: IAppProps["location"];
}

function SeriesColor(index: number) {
  const colors = [blue[500], green[500], cyan[500], indigo[500], lightBlue[500], lime[500], purple[500], teal[500]];

  return colors[index % colors.length];
}

export const Trend = observer((props: IProps) => {
  const trendStore = useContext(TrendContext);
  const [rangeDays, setRangeDays] = useState(-1);
  const errorHandler = useErrorHandler();
  const { location, match, classes } = props;

  const seriesColors: any = {};
  trendStore.metrics.map((metric: string, index: number) => {
    seriesColors[metric] = SeriesColor(index);
  });

  useEffect(() => {
    const params = qs.parse(location.search);
    const filterDates = getDateRange(params.from, params.to, 30);
    const fetchData = async () => {
      try {
        trendStore.setProject(match.params.projectName);
        await trendStore.loadTrend(decodeURIComponent(match.params.test), filterDates.dateFrom, filterDates.dateTo);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [location.search]);

  const handleSelectChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const val = parseInt(e.target.value as string, 10);
    setRangeDays(val);
    setDateRange(val);
  };

  return (
    <React.Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={rangeDays}
          onChange={handleSelectChange}
          input={<OutlinedInput labelWidth={0} name="get_latest" />}
        >
          <MenuItem value={30}>Last 30 Days</MenuItem>
          <MenuItem value={60}>Last 60 Days</MenuItem>
          <MenuItem value={90}>Last 90 Days</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h5">{trendStore.testName}</Typography>
      <Typography variant="subtitle1" paragraph={true}>
        Acceptance Tests
      </Typography>
      <TrendGraph classes={classes} />
    </React.Fragment>
  );
});

export default withStyles(styles)(Trend);
