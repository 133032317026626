import { Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { match } from "react-router";
import { ExistingOrgAccount, ExistingOrgInvite2 } from "../../../generatedApi";
import { useErrorHandler } from "../../../services/helpers/ErrorHandler";
import { Scope } from "../../../services/helpers/scopes";
import InvitesContext from "../../../stores/InvitesStore";
import MembersContext from "../../../stores/MembersStore";
import OrganizationsContext, { OrganizationsStore } from "../../../stores/OrganizationsStore";
import { styles } from "../../Styles/layout";
import { useDialog } from "../../UI/SimpleDialog";
import Snackbar from "../../UI/SnackBar";
import InviteListItem from "./InviteListItem";
import InviteUser from "./InviteUser";
import MemberListItem from "./MemberListItem";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ orgId: string; accountId: string }>;
}

export const ManageMembers = observer((props: IProps) => {
  const [isDialogOpen, toggleDialog] = useDialog();
  const [scopes, setScopes] = useState<string[]>([]);
  const errorHandler = useErrorHandler();

  const orgStore = useContext(OrganizationsContext);
  const invitesStore = useContext(InvitesContext);
  const membersStore = useContext(MembersContext);
  const { match, classes } = props;

  const orgId = match.params.orgId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        orgStore.loadOrganization(orgId);
        setScopes(await OrganizationsStore.loadScopes(orgId));
        invitesStore.setOrganization(orgId);
        invitesStore.loadInvites();
        membersStore.setOrganization(orgId);
        membersStore.loadMembers();
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [match.params.orgId]);

  return (
    <React.Fragment>
      <Snackbar />
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDialog}
          data-testid="invite-user-button"
          disabled={!scopes.includes(Scope.ORG_INVITE_CREATE)}
        >
          INVITE NEW USERS
        </Button>
      </FormControl>
      <Typography variant="h5">{orgStore.getOrganization(orgId).name} Members</Typography>
      <br />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.borderRight}>
              NAME
            </TableCell>
            <TableCell align="left">ROLE</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody data-testid="users-table">
          {invitesStore.invites.map((invite: ExistingOrgInvite2) => {
            return (
              <TableRow key={invite.uuid}>
                <InviteListItem invite={invite} scopes={scopes} />
              </TableRow>
            );
          })}
          {membersStore.members.map((member: ExistingOrgAccount) => {
            return (
              <TableRow key={member.uuid}>
                <MemberListItem org={orgStore.getOrganization(orgId)} member={member} scopes={scopes} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <InviteUser
        dialogActive={isDialogOpen}
        dialogToggle={toggleDialog}
        org={orgStore.getOrganization(orgId)}
        classes={classes}
      />
    </React.Fragment>
  );
});

export default withStyles(styles)(ManageMembers);
