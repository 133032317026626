import {
  createStyles,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircle";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { formatRoute } from "react-router-named-routes";
import AccountContext from "../../stores/AccountStore";
import AppContext from "../../stores/AppStore";
import { SETTINGS_PATH } from "../Routes";

const styles = (theme: Theme) =>
  createStyles({
    sIcon: {
      marginTop: `${theme.spacing(1)}px`,
      marginLeft: `${theme.spacing(1)}px`,
      color: theme.palette.common.white,
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const SettingsMenu = observer((props: IProps) => {
  const accountStore = useContext(AccountContext);
  const appStore = useContext(AppContext);

  const { history, classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const signOut = async () => {
    await appStore.logOut();
    accountStore.clear();
  };

  function handleSettingsClick() {
    history.push(formatRoute(SETTINGS_PATH, { accountId: accountStore.account.uuid }));
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Tooltip title="Settings">
        <IconButton
          data-testid="header_options_button"
          aria-label="Setting"
          aria-haspopup="true"
          aria-owns={anchorEl ? "options-menu" : undefined}
          onClick={handleClick}
        >
          <AccountIcon className={classes.sIcon} fontSize="large" />
        </IconButton>
      </Tooltip>
      <Popover
        id="options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <List>
          <ListItem data-testid="header_options_settings_button" button={true} onClick={handleSettingsClick}>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button={true}>
            <ListItemText primary="Help" />
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={signOut} data-testid="header_options_signout_button">
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  );
});

export default withRouter(withStyles(styles)(SettingsMenu));
