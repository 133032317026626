import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Conditional from "../../Conditional";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React, { useContext, useEffect, useState } from "react";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import TestMembershipsContext from "../../../stores/MembershipsStore";
import NotificationContext from "../../../stores/NotificationStore";
import SuitesContext from "../../../stores/SuiteStore";

import { ErrorBody, Suite, TestSuiteMembership } from "../../../generatedApi";
import { styles } from "../../Styles/layout";

export const AddTestToSuiteContext = React.createContext({});

interface IProps {
  projectName: string;
}

export const AddTestToSuite = (props: IProps) => {
  const { openAddDialog, setOpenAddDialog, selected } = useContext(AddTestToSuiteContext) as {
    openAddDialog: boolean;
    setOpenAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
    selected: TestSuiteMembership | undefined;
  };
  const [suiteError, setSuiteError] = useState(" ");
  const [loading, setLoading] = React.useState(false);
  const errorHandler = useErrorHandler();

  const membershipsStore = useContext(TestMembershipsContext);

  const suitesStore = useContext(SuitesContext);
  const [filteredSuites, setFilteredSuites] = useState<Suite[]>(suitesStore.suites);
  const notificationStore = useContext(NotificationContext);
  const { projectName } = props;

  function handleSearch(event: any) {
    const name = event.target.value;
    setFilteredSuites(suitesStore.suites.filter(suite => !name || suite.name.includes(name)));
  }

  async function handleSelectSuite(suite: Suite) {
    setLoading(true);
    if (selected && !selected.suite_names.includes(suite.name) && suite.uuid) {
      try {
        await membershipsStore.createMembership(suite.uuid, selected.test_name);
        setOpenAddDialog(false);
      } catch (error) {
        notificationStore.enqueueAutohideSnackbar(
          errorHandler(error, {
            403: (data: ErrorBody) => {
              return (
                <Typography color="inherit">
                  {mapErrorBody(data)}: <strong>{suite.name}</strong>
                </Typography>
              );
            },
          }),
          "error",
        );
      }
    }
    setLoading(false);
  }

  async function handleDeleteSuite(suite: Suite) {
    setLoading(true);
    if (suite.uuid && selected) {
      try {
        await membershipsStore.deleteMembership(suite.uuid, selected.test_name);
        setOpenAddDialog(false);
      } catch (error) {
        notificationStore.enqueueAutohideSnackbar(
          errorHandler(error, {
            403: (data: ErrorBody) => {
              return (
                <Typography color="inherit">
                  {mapErrorBody(data)}: <strong>{suite.name}</strong>
                </Typography>
              );
            },
          }),
          "error",
        );
      }
    }
    setLoading(false);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const payload = formData.get("suite") as string;
    if (payload) {
      try {
        const suite = { name: payload };
        const res = await suitesStore.createSuite(suite);
        setFilteredSuites(suitesStore.suites);
        handleSelectSuite(res);
      } catch (error) {
        setSuiteError(errorHandler(error, {
          303: () => "Benchmark suite already exists.",
          400: () => "Bad request.",
          401: () => "Unauthorized",
          403: (data: ErrorBody) => {
            notificationStore.enqueueAutohideSnackbar(
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{projectName}</strong>
              </Typography>,
              "error",
            );

            setOpenAddDialog(false);

            return " ";
          },
          404: () => "Project not found",
          415: () => "Content-Type header is not application/json.",
          422: () => "Input is semantically incorrect.",
        }) as string);
      }
    } else {
      setSuiteError("Error");
    }
    setLoading(false);
  }

  return (
    <Dialog open={openAddDialog} fullWidth={true}>
      <DialogTitle>Add Test {selected ? selected.test_name : ""} to a Suite:</DialogTitle>
      <DialogContent>
        <ListItem>
          <TextField
            fullWidth={true}
            variant="outlined"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </ListItem>
        <Divider />
        <List data-testid="associated_suites_list" style={{ maxHeight: 45 * 5, overflow: "auto" }}>
          {filteredSuites.map((suite: Suite, index: number) => {
            function listItemContents() {
              return (
                <React.Fragment>
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <Conditional if={selected ? selected.suite_names.includes(suite.name) : false}>
                      <CheckIcon data-testid="selected_suite" fontSize="small" />
                    </Conditional>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Typography variant="body2" color="primary">
                        {suite.name}
                      </Typography>
                    }
                  />
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <Conditional if={selected ? selected.suite_names.includes(suite.name) : false}>
                      <IconButton
                        data-testid="remove_suite_button"
                        disableRipple={true}
                        onClick={() => handleDeleteSuite(suite)}
                        disabled={loading}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Conditional>
                  </ListItemIcon>
                </React.Fragment>
              );
            }

            if (selected && selected.suite_names.includes(suite.name)) {
              return (
                <ListItem key={index} onClick={() => handleSelectSuite(suite)} button={false} disabled={loading}>
                  {listItemContents()}
                </ListItem>
              );
            } else {
              return (
                <ListItem key={index} onClick={() => handleSelectSuite(suite)} button={true} disabled={loading}>
                  {listItemContents()}
                </ListItem>
              );
            }
          })}
        </List>
        <Divider />
      </DialogContent>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography>OR:</Typography>
          <Typography variant="h6">Add to a new Suite</Typography>
          <FormControl margin="dense" required={true} fullWidth={true}>
            <TextField
              data-testid="manage_suites_new_name"
              name="suite"
              label="Suite name"
              helperText={suiteError}
              error={suiteError !== " "}
              disabled={loading}
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button data-testid="manage_suites_new_button" type="submit" color="secondary" disabled={loading}>
            Create Suite
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddTestToSuite);
